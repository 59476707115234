import React, { Component } from "react";
import Select from "react-select";

import DHModal from "../../components/common/DHModal";
import DHSelect from "../../components/common/DHSelect";
import Input from "../../components/common/Input";
import Alert from "../../components/common/Alert";

import ToastService from "../../services/ToastService";
import CrmIntegrationsService from "../../services/CrmIntegrationsService";
import UserService from "../../services/UserService";

import { CRM_INTEGRATIONS } from "../../constants/CRMIntegration";
import { STATUS } from "../../constants/CommonConstants";

import "../../styles/css/scenes/connections.css";

class ConnectTebraModal extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			selectedIntegration: null,
			selectableIntegrations: [],
			integration: null,

			tenantId: "", // The practice ID
			practiceName: "", // The practice name
			username: "",
			password: "",
			clientSecret: "", // The admin api key

			lastTestStatus: false,
			showAlertIntegrationNotFound: false,
			showAlertTestSuccess: false,
			showAlertTestFailed: false,
			showDisconnectModal: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	async componentDidMount() {
		await this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	async resetComponent() {
		let { show } = this.props;

		// If we are not showing the dialog, don't fetch anything
		if (!show) {
			return;
		}

		await this.fetchIntegrations();
		await this.onIntegrationSelected();
	}

	onClickDisconnect = () => {
		this.update({ showDisconnectModal: true });
	};

	disconnectIntegration = async confirm => {
		if (!confirm) {
			await this.update({ showDisconnectModal: false });
			return;
		}

		if (this.props.disconnectIntegration) {
			this.props.disconnectIntegration();
		}

		await this.update({ showDisconnectModal: false });
	};

	fetchIntegrations = async () => {
		let integrations = await CrmIntegrationsService.fetch(UserService.getActiveLocation().id, {
			type: CRM_INTEGRATIONS.type.tebra.name,
			status: [STATUS.active]
		});

		if (!integrations) {
			this.update({
				selectableIntegrations: []
			});
			return;
		}
		let selectableIntegrations = integrations.map(i => {
			return { value: i.id, label: i.name ? i.name : CRM_INTEGRATIONS[i.crm_type].displayName };
		});

		this.update({
			selectableIntegrations
		});
	};

	onIntegrationSelected = async integrationOption => {
		let integrationId = null;

		if (integrationOption) {
			integrationId = integrationOption.value;
		}

		if (!integrationId) {
			// Default to the passed in integration id
			integrationId = this.props.integrationId;
		}

		// Set loading to true, and reset dirty bit
		await this.update({
			loading: true,
			integration: null
		});

		try {
			// Fetch the integration object from the backend
			let integration = await CrmIntegrationsService.fetchRestIntegration(integrationId);

			if (!integration || integration.status === STATUS.deleted) {
				// Show integration not found dialog
				await this.update({
					showAlertIntegrationNotFound: true
				});

				return;
			}

			let username = integration.meta_data.credentials.login ? integration.meta_data.credentials.login : "";
			let password = integration.meta_data.credentials.password ? integration.meta_data.credentials.password : "";
			let clientSecret = integration.meta_data.credentials.clientSecret ? integration.meta_data.credentials.clientSecret : "";

			// Practice info
			let practiceId = integration.meta_data.practiceInfo.practiceId ? integration.meta_data.practiceInfo.practiceId : "";
			let practiceName = integration.meta_data.practiceInfo.practiceName ? integration.meta_data.practiceInfo.practiceName : "";

			// Save this integration in our state
			await this.update({
				integration,
				username,
				password,
				clientSecret,
				practiceId,
				practiceName,
				selectedIntegration: { value: integration.id, label: integration.name ? integration.name : CRM_INTEGRATIONS[integration.crm_type].displayName }
			});
		} catch (error) {
			console.log(error.message);
		}
		await this.update({ loading: false });
	};

	onUsernameChange = async event => {
		await this.update({ username: event.target.value });
	};

	onPasswordChange = async event => {
		await this.update({ password: event.target.value });
	};

	onClientSecretChange = async event => {
		await this.update({ clientSecret: event.target.value });
	};

	onTenantIdChange = async event => {
		await this.update({ tenantId: event.target.value });
	};

	onPracticeNameChange = async event => {
		await this.update({ practiceName: event.target.value });
	};

	onTestConnection = async event => {
		let { selectedIntegration } = this.state;

		await this.update({ loading: true });

		let testResponse = await CrmIntegrationsService.testRestIntegration({
			integrationId: selectedIntegration.value,
			login: this.state.username,
			password: this.state.password,
			clientSecret: this.state.clientSecret,
			tenantId: this.state.tenantId,
			practiceName: this.state.practiceName
		});

		if (testResponse.status) {
			ToastService.info(`Connection Successful!`);
			await this.update({ lastTestStatus: testResponse.status, showAlertTestSuccess: true, loading: false });
			return;
		}

		await this.update({ lastTestStatus: testResponse.status, loading: false, showAlertTestFailed: !testResponse.status });
	};

	onConfirmTestSuccess = async event => {
		await this.update({ showAlertTestSuccess: false });
	};

	onConfirmTestFailed = async event => {
		await this.update({ showAlertTestFailed: false });
	};

	onConfirmIntegrationNotFound = async event => {
		await this.update({ showAlertIntegrationNotFound: false });
	};

	onCancel = async event => {
		let { onHide } = this.props;

		await this.update({ showLocationSelector: false });

		if (onHide) {
			onHide();
		}
	};

	render() {
		let { show, integrationId } = this.props;

		let {
			username,
			password,
			showAlertTestSuccess,
			showAlertTestFailed,
			showAlertIntegrationNotFound,
			showDisconnectModal,

			selectedIntegration,
			selectableIntegrations
		} = this.state;

		let isSuperOrCs = UserService.isSuperAdminOrCustomerSuccess();
		let showDisconnectButton = integrationId && isSuperOrCs;

		return (
			<>
				<DHModal title="Connect to Tebra" show={show} onHide={this.onCancel}>
					<>
						{selectableIntegrations.length > 1 && selectedIntegration && (
							<div className="Connections-modal__select">
								<div>Integration</div>
								<Select
									id={`selectable-integrations`}
									options={selectableIntegrations}
									value={selectedIntegration}
									onChange={this.onIntegrationSelected}
									placeholder={`${CRM_INTEGRATIONS.type.tebra.displayName} Integration`}
								/>
							</div>
						)}

						<>
							<div className="modal__flex-container">
								<div className="Connections-modal__input">
									<Input label="Username" name="tebra-username" id="tebra-username" type="text" onChange={this.onUsernameChange} value={username} required />
								</div>

								<div className="Connections-modal__input">
									<Input
										label="Password"
										name="tebra-password"
										id="tebra-password"
										type="password"
										onChange={this.onPasswordChange}
										value={password}
										required
									/>
								</div>
							</div>

							<div className="modal__actions">
								<button
									id="tebra-cancel"
									className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
									disabled={this.state.loading}
									onClick={this.onCancel}
								>
									Cancel
								</button>

								{showDisconnectButton && (
									<button
										id="simple-practice-disconnect"
										className={`mb-button mb-button--cancel ${this.state.loading ? "mb-button--disabled" : ""}`}
										disabled={this.state.loading}
										onClick={this.onClickDisconnect}
									>
										Disconnect
									</button>
								)}

								<button
									id="tebra-test-connection"
									className={`mb-button ${this.state.loading ? "mb-button--disabled" : ""}`}
									disabled={this.state.loading}
									onClick={this.onTestConnection}
								>
									Test Connection
								</button>
							</div>
						</>
					</>
				</DHModal>

				<Alert type="success" show={showAlertTestSuccess} title="Connection Successful" confirm="OK" onClose={this.onConfirmTestSuccess}>
					The connection was successful!
				</Alert>
				<Alert type="error" show={showAlertTestFailed} title="Connection Failed" confirm="OK" onClose={this.onConfirmTestFailed}>
					The connection failed. Please verify credentials and try again.
				</Alert>
				<Alert type="error" show={showAlertIntegrationNotFound} title="Not Found" confirm="OK" onClose={this.onConfirmIntegrationNotFound}>
					The Tebra integration was not found.
				</Alert>
				<Alert type="info" show={showDisconnectModal} title={"Disconnect"} confirm={"Yes"} cancel={"No"} onClose={this.disconnectIntegration}>
					<div>Are you sure you would like to disconnect the integration?</div>
				</Alert>
			</>
		);
	}
}
export default ConnectTebraModal;
