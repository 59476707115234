import React, { PureComponent } from "react";
import * as Icon from "react-feather";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { withTranslation } from "react-i18next";

import ListHeader from "./ListHeader";

import { SORT_ORDER } from "../../constants/Appointments";
import "../../styles/css/components/commons/list.css";
import ListPaginationHeader from "./ListPaginationHeader";

class List extends PureComponent {
	renderPaginationHeader() {
		let { id, pagination, pages, currentPage, totalCount, onPaginateChange, limit, onLimitChange } = this.props;

		if (!pagination) {
			return null;
		}

		return (
			<ListPaginationHeader
				id={id}
				pages={pages}
				totalCount={totalCount}
				currentPage={currentPage}
				onPaginateChange={onPaginateChange}
				limit={limit}
				onLimitChange={onLimitChange}
			/>
		);
	}

	renderHeader() {
		let { headers } = this.props;

		if (!headers) {
			return null;
		}

		let columns = Object.keys(headers.items).map(key => headers.items[key]);

		if (columns.length < 1) {
			return null;
		}

		return <ListHeader columns={columns} headers={headers} resolveWidth={this.resolveWidth} renderSort={this.renderSort} />;
	}

	resolveWidth(item) {
		let classNames = "";

		if (item.width && typeof item.width == "function") {
			classNames += ` dh-list-record-${item.width()}`;
		} else if (item.width) {
			classNames += ` dh-list-record-${item.width}`;
		} else if (item.width === "none") {
			classNames += ` dh-list-record-none`;
		} else {
			classNames += " dh-list-record-auto";
		}
		if (item.widthXs) {
			classNames += ` dh-list-record-xs-${item.widthXs}`;
		} else if (item.widthXs === 0) {
			classNames += ` dh-list-hidden-xs`;
		}
		if (item.widthSm) {
			classNames += ` dh-list-record-sm-${item.widthSm}`;
		} else if (item.widthSm === 0) {
			classNames += ` dh-list-hidden-sm`;
		}
		if (item.widthMd) {
			classNames += ` dh-list-record-md-${item.widthMd}`;
		} else if (item.widthMd === 0) {
			classNames += ` dh-list-hidden-md`;
		}
		if (item.widthLg) {
			classNames += ` dh-list-record-lg-${item.widthLg}`;
		} else if (item.widthLg === 0) {
			classNames += ` dh-list-hidden-lg`;
		}

		return classNames.trim();
	}

	renderList = () => {
		let { items } = this.props;

		return (
			<AutoSizer>
				{({ height, width }) => (
					<FixedSizeList
						height={height}
						itemCount={items.length}
						itemSize={40}
						width={width}
						itemKey={index => {
							return items[index] && items[index].id ? items[index].id : index;
						}}
					>
						{this.renderRecord}
					</FixedSizeList>
				)}
			</AutoSizer>
		);
	};

	renderRecord = ({ style, index }) => {
		let { headers, items, onRecordClicked, renderRecord, loadedAll, onLoadMore, t, pagination } = this.props;

		let columns = Object.keys(headers.items).map(key => headers.items[key]);
		let item = items[index];

		let recordData = renderRecord(item);
		if (!recordData) {
			return null;
		}

		let showLoadMore = !loadedAll && items.length === index + 1 && !pagination;

		return (
			<>
				{!showLoadMore && (
					<div style={style} key={`row-${item.id}`} className={`dh-list-record ${index % 2 === 0 ? "dh-list-record--odd" : ""}`}>
						{columns.map((header, i) => {
							let recordClickHandler = () => {};

							if (typeof onRecordClicked === "function") {
								recordClickHandler = () => (!header.notClickable ? onRecordClicked(item) : null);
							}

							let rowWidthClass = this.resolveWidth(header);
							// No need to add these to the dom since they won't be displayed anyways. Technically the css classes would hide these from being shown
							if (rowWidthClass === "dh-list-record-0") {
								return null;
							}

							return (
								<div key={`row-${index}-col-${i}`} onClick={recordClickHandler} className={`dh-list-record__cell ${rowWidthClass}`}>
									{typeof recordData[i] !== "undefined" ? recordData[i] : ""}
								</div>
							);
						})}
					</div>
				)}
				{showLoadMore && (
					<div style={style}>
						<div className="dh-list-loadmore" onClick={onLoadMore}>
							{t("Load More")}
						</div>
					</div>
				)}
			</>
		);
	};

	renderSort = field => {
		const { sortField, sortOrder } = this.props;
		return sortField === field && (sortOrder === SORT_ORDER.asc ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	render() {
		let { items, loading, noDataTitle, noDataSubtitle, noDataIcon, noDataButton, noDataButtonOnClick, t, pagination, minHeight } = this.props;

		return (
			<>
				{this.renderPaginationHeader()}
				{this.renderHeader()}
				{items && items.length > 0 && (
					<div className="dh-list" style={{ minHeight }}>
						{this.renderList()}
					</div>
				)}
				{!loading && items && items.length === 0 && (
					<div className="dh-list-none">
						{noDataIcon ? <div className="dh-list-none__graphic">{noDataIcon}</div> : ""}
						<div className="dh-list-none__text">{noDataTitle ? noDataTitle : t("No data was found...")}</div>
						{noDataSubtitle && <div>{noDataSubtitle}</div>}
						{noDataButton && (
							<div className="mb-button mb-button--fit dh-list-none__button" onClick={noDataButtonOnClick}>
								{noDataButton}
							</div>
						)}
					</div>
				)}
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(List);
