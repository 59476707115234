import React, { Component } from "react";
import ReactTable from "react-table";
import moment from "moment";
import matchSorter from "match-sorter";
import ReactSwitch from "react-switch";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";

import { AnalyticsService } from "../../../services/AnalyticsService";
import PreferenceService from "../../../services/PreferenceService";

import Spinners from "../../../components/common/Spinners";
import NoDataComponent from "../../../components/common/NoDataComponent";
import LegacyPage from "../../../components/common/LegacyPage";

import { AnalyticsConstants } from "../../../constants/AnalyticsConstants";

import "../../../styles/css/components/commons/react-table.css";

class CSReviewAnalytics extends Component {
	state = {
		loading: true,
		reviewStats: [],
		filteredReviewStats: [],
		searchString: "",
		locationDesc: false,
		companyDesc: true,
		initialRatingDesc: true,
		initialReviewsDesc: true,
		latestRatingsDesc: true,
		latestReviewsDesc: true,
		increaseRateDesc: true,
		isAutoResponderEnabledDesc: true,
		showFavoritesOnly: PreferenceService.get("show_favorites") ? true : false
	};
	toggleFavoritesOnlySwitch() {
		PreferenceService.set("show_favorites", !this.state.showFavoritesOnly);
		this.setState({ showFavoritesOnly: !this.state.showFavoritesOnly }, this.fetchReviewStats);
	}
	async fetchReviewStats() {
		this.setState({ loading: true, reviewStats: [] });

		let data = await AnalyticsService.getReviewStats({ favoritesOnly: this.state.showFavoritesOnly });

		if (!data) {
			this.setState({ loading: false });
			return;
		}

		this.setState({ reviewStats: data });
	}

	componentDidMount() {
		this.fetchReviewStats();
	}
	async getLastXErrorEvents(locationId, eventName, type) {
		try {
			const lastXEvents = await AnalyticsService.getLastXEvents(AnalyticsConstants.APP_NAME.platform, eventName, locationId, 5);
			this.setState({ [`lastXErrorEvents${locationId}${type}`]: lastXEvents || [] });
		} catch (error) {
			console.log(error);
		}
	}

	renderStatusColumn(row, type, status, eventName) {
		const lastXErrorEvents = this.state[`lastXErrorEvents${row.value.id}${type}`] || [];
		if (status === "active")
			return (
				<div>
					{lastXErrorEvents.length <= 0 ? (
						<span className="label label-green" onMouseOver={() => this.getLastXErrorEvents(row.value.id, eventName, type)}>
							Active
						</span>
					) : (
						<div>
							<ReactTooltip id={`Webchat Status Error for ${type}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
								{lastXErrorEvents.map((anError, key) => (
									<div key={key} style={{ padding: "8px" }}>
										<span className="label label-danger">Error</span> {moment(anError.created_at).format("YYYY-MM-DD HH:mm:ss")}
									</div>
								))}
							</ReactTooltip>
							<span data-tip data-for={`Webchat Status Error for ${type}`} className="label label-green">
								Active
							</span>
						</div>
					)}
				</div>
			);
		if (status === "failed")
			return (
				<div>
					{lastXErrorEvents.length <= 0 ? (
						<span className="label label-danger" onMouseOver={() => this.getLastXErrorEvents(row.value.id, eventName, type)}>
							Error
						</span>
					) : (
						<div>
							<ReactTooltip id={`Webchat Status Error for ${type}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
								{lastXErrorEvents.map((anError, key) => (
									<div key={key} style={{ padding: "8px" }}>
										<span className="label label-danger">Error</span> {moment(anError.created_at).format("YYYY-MM-DD HH:mm:ss")}
									</div>
								))}
							</ReactTooltip>
							<span data-tip data-for={`Webchat Status Error for ${type}`} className="label label-danger">
								Error
							</span>
						</div>
					)}
				</div>
			);
		return (
			<div>
				{lastXErrorEvents.length <= 0 ? (
					<span className="label label-warning" onMouseOver={() => this.getLastXErrorEvents(row.value.id, eventName, type)}>
						Inactive
					</span>
				) : (
					<div>
						<ReactTooltip id={`Webchat Status Error for ${type}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
							{lastXErrorEvents.map((anError, key) => (
								<div key={key} style={{ padding: "8px" }}>
									<span className="label label-danger">Error</span> {moment(anError.created_at).format("YYYY-MM-DD HH:mm:ss")}
								</div>
							))}
						</ReactTooltip>
						<span data-tip data-for={`Webchat Status Error for ${type}`} className="label label-warning">
							Inactive
						</span>
					</div>
				)}
			</div>
		);
	}
	handleGeneralSearchOnChange(event) {
		const searchString = event.target.value;
		const filteredReviewStats = [{ id: "all", value: searchString }];

		// NOTE: this completely clears any COLUMN filters
		this.setState({ searchString, filteredReviewStats });
	}
	render() {
		const { reviewStats, loading, filteredReviewStats, searchString, showFavoritesOnly } = this.state;
		return (
			<LegacyPage>
				<div>
					<div className="row wrapper white-bg page-heading">
						<div className="col-sm-6 col-md-8 col-lg-9">
							<h2>
								Reviews Analytics{" "}
								<span
									id="refresh"
									style={{ marginLeft: "10px", cursor: "pointer", userSelect: "none", transform: "translate(0px, 3px)", position: "absolute" }}
									onClick={() => this.fetchReviewStats()}
								>
									<Icon.RefreshCcw />
								</span>
								<br />
								<br />
								<input
									style={{ width: "400px" }}
									type="text"
									placeholder="Filter Reviews Analytics..."
									className="form-control filter-textbox"
									value={searchString}
									onChange={e => this.handleGeneralSearchOnChange(e)}
								/>
							</h2>
						</div>
						<div
							className="col-sm-6 col-md-4 col-lg-3"
							style={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
								marginTop: "20px",
								paddingRight: "20px"
							}}
						>
							<div>
								<b>All</b>
							</div>
							<div style={{ marginRight: "5px", marginLeft: "5px" }}>
								<ReactSwitch
									id="show_favorites_only"
									onChange={() => this.toggleFavoritesOnlySwitch()}
									checked={showFavoritesOnly}
									uncheckedIcon={false}
									checkedIcon={false}
									onColor="#4A90E2"
									type="text"
								/>
							</div>
							<div>
								<b>Favourites</b>
							</div>
						</div>
					</div>
					<div className="wrapper wrapper-content">
						<div className="ibox">
							<div className="row">
								<div className="col-sm-12">
									<div className="ibox-content">
										<ReactTable
											data={reviewStats}
											filtered={filteredReviewStats}
											onFilteredChange={filteredReviewStats => this.setState({ filteredReviewStats })}
											defaultSorted={[
												{
													id: "location",
													desc: false
												}
											]}
											onSortedChange={sorted => {
												if (sorted.length !== 1) {
													return;
												}
												this.setState({ [sorted[0].id + "Desc"]: sorted[0].desc });
											}}
											columns={[
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Company {this.state.companyDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "company_name",
													className: "DHReactTable__cell",
													id: "company",
													Cell: row => <span className="DHReactTable__row">{row.value}</span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Location {this.state.locationDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "name",
													className: "DHReactTable__cell",
													id: "location",
													Cell: row => <span className="DHReactTable__row">{row.value}</span>
												},
												{
													Header: () => <div className="DHReactTable__header--container">GMB Status</div>,
													headerClassName: "DHReactTable__row--header",
													accessor: d => d,
													className: "DHReactTable__cell",
													id: "gmbStatus",
													filterable: true,
													width: 90,
													sortable: false,
													filterMethod: (filter, row) => {
														if (filter.value === "all") return true;
														if (filter.value === "active") return row[filter.id].gmb_status === "active";
														if (filter.value === "inactive") return row[filter.id].gmb_status === "inactive";
														if (filter.value === "failed") return row[filter.id].gmb_status === "failed";
													},
													Filter: ({ filter, onChange }) => (
														<select
															className="form-control"
															onChange={event => onChange(event.target.value)}
															style={{ width: "100%" }}
															value={filter ? filter.value : "all"}
														>
															<option value="all">Show All</option>
															<option value="active">Active</option>
															<option value="inactive">Inactive</option>
															<option value="failed">Error</option>
														</select>
													),
													Cell: row => this.renderStatusColumn(row, "GMB", row.value.gmb_status, AnalyticsConstants.EVENT_NAME.gmbConnectionError)
												},
												{
													Header: () => <div className="DHReactTable__header--container">FB Status</div>,
													headerClassName: "DHReactTable__row--header",
													accessor: d => d,
													className: "DHReactTable__cell",
													id: "fbStatus",
													width: 90,
													filterable: true,
													sortable: false,
													filterMethod: (filter, row) => {
														if (filter.value === "all") return true;
														if (filter.value === "active") return row[filter.id].fb_status === "active";
														if (filter.value === "inactive") return row[filter.id].fb_status === "inactive";
														if (filter.value === "failed") return row[filter.id].fb_status === "failed";
													},
													Filter: ({ filter, onChange }) => (
														<select
															className="form-control"
															onChange={event => onChange(event.target.value)}
															style={{ width: "100%" }}
															value={filter ? filter.value : "all"}
														>
															<option value="all">Show All</option>
															<option value="active">Active</option>
															<option value="inactive">Inactive</option>
															<option value="failed">Error</option>
														</select>
													),
													Cell: row => this.renderStatusColumn(row, "FB", row.value.fb_status, AnalyticsConstants.EVENT_NAME.fbConnectionError)
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Initial Rating {this.state.initialRatingDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "initial_ratings",
													className: "DHReactTable__cell",
													id: "initialRating",
													Cell: row => <span className="DHReactTable__row">{!row.value || row.value === -1 ? "N/A" : row.value.toFixed(1)} </span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Initial Reviews {this.state.initialReviewsDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "initial_reviews",
													className: "DHReactTable__cell",
													id: "initialReviews",
													Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Current Ratings {this.state.latestRatingsDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "latest_ratings",
													className: "DHReactTable__cell",
													id: "latestRatings",
													Cell: row => <span className="DHReactTable__row">{!row.value || row.value === -1 ? "N/A" : row.value.toFixed(1)} </span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Current Reviews {this.state.latestReviewsDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "latest_reviews",
													className: "DHReactTable__cell",
													id: "latestReviews",
													Cell: row => <span className="DHReactTable__row">{row.value === -1 ? "N/A" : row.value} </span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															% Change in Reviews {this.state.increaseRateDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: row => row,
													className: "DHReactTable__cell",
													id: "increaseRate",
													Cell: row => {
														let increaseRateLabel = "";
														if (
															!row.value.initial_reviews ||
															row.value.initial_reviews < 0 ||
															!row.value.latest_reviews ||
															row.value.latest_reviews < row.value.initial_reviews
														) {
															increaseRateLabel = "N/A";
														} else {
															increaseRateLabel = ((row.value.latest_reviews - row.value.initial_reviews) / row.value.initial_reviews) * 100;
															increaseRateLabel = increaseRateLabel.toFixed(2) + "%";
														}
														return <span className="DHReactTable__row">{increaseRateLabel}</span>;
													},
													resizable: false
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Auto Review Responder Enabled{" "}
															{this.state.isAutoResponderEnabledDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "reviews_auto_response",
													className: "DHReactTable__cell",
													id: "isAutoReviewResponderEnabled",
													Cell: row => <span className="DHReactTable__row">{row.value ? <Icon.Check /> : <Icon.X />}</span>,
													resizable: false
												},
												{
													Header: "All",
													id: "all",
													width: 0,
													headerClassName: "hide",
													resizable: false,
													sortable: false,
													Filter: () => {},
													getProps: () => {
														return {
															// style: { padding: "0px"}
														};
													},
													filterMethod: (filter, rows) => {
														// using match-sorter
														// it will take the content entered into the "filter"
														// and search for it in EITHER the firstName or lastName
														const result = matchSorter(rows, filter.value, {
															keys: ["company", "location"],
															threshold: matchSorter.rankings.CONTAINS
														});
														return result;
													},
													filterAll: true
												}
											]}
											LoadingComponent={() => (
												<div className="Common__spinnerdiv--center">
													<Spinners loading={loading} type="tail-fade" size="120px" />
												</div>
											)}
											NoDataComponent={() => <NoDataComponent loading={loading} />}
											showPagination={reviewStats.length > 10}
											PreviousComponent={props => (
												<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
													<Icon.ChevronLeft style={{ paddingTop: "4px" }} size="20" />
												</button>
											)}
											NextComponent={props => (
												<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
													<Icon.ChevronRight style={{ paddingTop: "4px" }} size="20" />
												</button>
											)}
											defaultPageSize={10}
											className="-striped -highlight"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LegacyPage>
		);
	}
}

export default CSReviewAnalytics;
