import React, { Component } from "react";
import * as Icon from "react-feather";
import { withRouter } from "react-router-dom";

import Kichiri from "../../services/KichiriService";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import LocationService from "../../services/LocationService";
import CompanyService from "../../services/CompanyService";
import ToastService from "../../services/ToastService";
import NotificationService from "../../services/NotificationService";

import Alert from "../../components/common/Alert";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import SearchInput from "../../components/common/SearchInput";
import List from "../../components/common/List";
import Action from "../../components/common/Action";
import withLocation from "../../components/common/WithLocation";

import { LOCATION_COLUMNS } from "../../constants/LocationConstants";
import { SORT_ORDER } from "../../constants/CommonConstants";
import { STATUS } from "../../constants/CompanyConstants";

import "../../styles/css/components/commons/react-table.css";

class Locations extends Component {
	constructor(props) {
		super(props);

		this.state = {
			locations: [],
			activeLocationId: UserService.getActiveLocation().id,
			activeCompanyId: UserService.getActiveCompany().id,
			limitDefault: 10,
			limit: 25,
			pageSize: 50,
			loading: true,
			loadedAll: false,
			searchTerm: "",
			sortField: LOCATION_COLUMNS.name.sortField,
			sortOrder: SORT_ORDER.asc,
			hasError: false,
			success: false,
			hasWarning: false,
			selectedRow: undefined,
			deactivateWarning: false
		};
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.refreshLocationsList();
	}

	onLocationChanged = async location => {
		let locationId = location.id;
		let companyId = location.company_id;
		await this.update({ activeLocationId: locationId, activeCompanyId: companyId });
		await this.refreshLocationsList();
	};

	async refreshLocationsList() {
		let { searchTerm, sortField, sortOrder, limit, activeCompanyId } = this.state;

		let locations = await CompanyService.fetchLocations({ companyId: activeCompanyId, searchTerm, sortField, sortOrder, limit });

		if (!locations) {
			await this.update({ loading: false });
			return;
		}

		await this.update({ locations, loading: false, loadedAll: locations.length < limit });
	}

	onSearchChange = async value => {
		await this.update({
			searchTerm: value
		});
		await this.refreshLocationsList();
	};

	handleUpdateLocationClick = row => {
		this.props.history.push(`/settings/locations/${row.id}`);
	};

	handleUpdateWizardLocationClick = row => {
		this.props.history.push(`/settings/location-wizard/${row.id}`);
	};

	confirmLocationDeleteClick = row => {
		let { activeLocationId } = this.state;
		const rowToDeleteId = row.id;

		if (activeLocationId === rowToDeleteId) {
			ToastService.error(`You cannot delete a location you are currently administering`);
			return;
		}

		this.setState({
			hasWarning: true,
			selectedRow: row
		});
	};

	confirmLocationDeactivateClick = row => {
		this.setState({
			deactivateWarning: true,
			selectedRow: row
		});
	};

	handleDeleteLocationClick = async row => {
		let locationId = row.id;

		this.setState({
			hasWarning: false,
			selectedRow: undefined
		});

		let sucess = await LocationService.deleteLocation(locationId);

		if (!sucess) {
			this.setState({ hasError: true });
			return;
		}

		this.setState({ success: true });
		this.refreshLocationsList();

		NotificationService.publish("locationUpdated", locationId);
	};

	onConfirmAlertClose = confirmed => {
		if (confirmed) {
			this.handleDeleteLocationClick(this.state.selectedRow);
		}
		this.setState({
			messageDelete: false,
			hasWarning: false
		});
	};

	onConfirmDeactivateClose = async confirmed => {
		await this.update({
			deactivateWarning: false
		});
		if (confirmed) {
			await this.handleDeactivateLocationClick(this.state.selectedRow);
		}
	};

	handleDeactivateLocationClick = async row => {
		try {
			await this.update({
				deactivateWarning: false,
				selectedRow: undefined
			});

			const locationData = {
				locationId: row.id,
				company: row.company_id,
				status: row && row.status === STATUS.inactive ? STATUS.active : STATUS.inactive
			};

			let success = await LocationService.update(locationData);
			if (!success) {
				ToastService.error(`Sorry, an error occurred trying to ${row && row.status === STATUS.inactive ? "activate" : "deactivate"}  the location.`);
				return;
			}

			ToastService.info("Location saved.");
			this.refreshLocationsList();

			let location = UserService.getActiveLocation();
			location.status = locationData.status;

			UserService.setActiveLocation(location);

			NotificationService.publish("locationUpdated", row.id);
		} catch (error) {
			console.log(error);
		}
	};

	onLoadMore = async () => {
		let { limit, pageSize } = this.state;

		await this.update({
			limit: limit + pageSize
		});

		await this.refreshLocationsList();
	};

	onRecordClicked = async item => {
		// nothing for now
	};

	getHeaders = () => {
		let headers = {
			items: LOCATION_COLUMNS,
			sortBy: this.sortBy
		};

		return headers;
	};

	sortBy = async sortField => {
		let { sortOrder } = this.state;
		sortOrder = sortOrder === SORT_ORDER.asc ? SORT_ORDER.desc : SORT_ORDER.asc;
		await this.update({ loading: true, sortField, sortOrder });
		await this.refreshLocationsList();
		await this.update({ loading: false });
	};

	renderRecord = recordData => {
		let { activeLocationId } = this.state;

		let record = [
			recordData.id,
			<div className="locations__name">
				{recordData.name} {recordData.id === activeLocationId ? <span className="badge">Admin</span> : ""}
			</div>,
			<span>{`${LocationService.getFullAddress(recordData)}, ${recordData.city}, ${recordData.state_prov}, ${recordData.country}`}</span>,
			<span>{recordData.phone}</span>,
			this.renderRecordActions(recordData)
		];

		return record;
	};

	renderRecordActions = recordData => {
		const user = UserService.get();
		return (
			<div className="companies__actions">
				{user.GroupPermission.update_locations && (
					<>
						<Action id={`update-${recordData.id}`} label="Update" icon={Icon.Edit} onClick={() => this.handleUpdateLocationClick(recordData)} />
						<Action
							id={`update-wizard-${recordData.id}`}
							label="Update Wizard"
							icon={Icon.FastForward}
							onClick={() => this.handleUpdateWizardLocationClick(recordData)}
						/>
					</>
				)}
				{user.GroupPermission.delete_locations && (
					<Action id={`delete-${recordData.id}`} label="Delete" icon={Icon.Trash2} onClick={() => this.confirmLocationDeleteClick(recordData)} />
				)}
				{UserService.isSuperAdminOrCustomerSuccess() && user.GroupPermission.update_locations && (
					<Action
						id={`deactivate-${recordData.id}`}
						label={recordData.status === STATUS.inactive ? "Activate" : "Deactivate"}
						icon={recordData.status === STATUS.inactive ? Icon.Zap : Icon.Power}
						onClick={() => this.confirmLocationDeactivateClick(recordData)}
					/>
				)}
			</div>
		);
	};

	render() {
		const user = UserService.get();
		const { locations, loading, loadedAll, sortField, sortOrder, selectedRow } = this.state;

		return (
			<Page>
				<Header title="Locations">
					{user.GroupPermission.create_locations && (
						<Action id="new-location" label="Create" icon={Icon.Plus} onClick={() => this.props.history.push(`/settings/locations/create`)} />
					)}
					{user.GroupPermission.create_locations && (
						<Action
							id="new-location-wizard"
							label="Create with Wizard"
							icon={Icon.FastForward}
							onClick={() => this.props.history.push(`/settings/location-wizard/create`)}
						/>
					)}
				</Header>
				<div className="companies__search">
					<SearchInput placeholder="Search ..." onChange={this.onSearchChange} />
				</div>
				<List
					items={locations}
					loading={loading}
					loadedAll={loadedAll}
					sortField={sortField}
					sortOrder={sortOrder}
					headers={this.getHeaders()}
					renderRecord={this.renderRecord}
					onRecordClicked={this.onRecordClicked}
					onLoadMore={this.onLoadMore}
					noDataTitle="Locations not found"
					noDataIcon={<Icon.AlertCircle />}
				/>
				<Alert
					type="error"
					show={this.state.hasError}
					title="Error"
					confirm="OK"
					onClose={() => {
						this.setState({ hasError: false });
					}}
				>
					<div>Error deleting the Location.</div>
				</Alert>
				<Alert
					type="success"
					show={this.state.success}
					title="Success"
					confirm="OK"
					onClose={() => {
						this.setState({ success: false });
					}}
				>
					<div>The location has been deleted.</div>
				</Alert>
				<Alert type="warning" show={this.state.hasWarning} title="Are you sure?" confirm="Yes" cancel="No" onClose={this.onConfirmAlertClose}>
					<div>Are you sure you want to delete this location?</div>
				</Alert>
				<Alert type="warning" show={this.state.deactivateWarning} title="Are you sure?" confirm="Yes" cancel="No" onClose={this.onConfirmDeactivateClose}>
					<div>Are you sure you want to {selectedRow && selectedRow.status === STATUS.inactive ? "activate" : "deactivate"} this location?</div>
				</Alert>
			</Page>
		);
	}
}

export default withRouter(withLocation(Locations));
