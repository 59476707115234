import React, { Component } from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import moment from "moment";

import SendReviewInvites from "../../scenes/Invites/SendReviewInvites";
import LocationSelector from "../../scenes/TopBar/LocationSelector";
import CompanySelector from "../../scenes/TopBar/CompanySelector";
import LocationService from "../../services/LocationService";
import NotificationService from "../../services/NotificationService";
import UserService from "../../services/UserService";
import SupportChatService from "../../services/SupportChatService";
import ToastService from "../../services/ToastService";

import HelpMenu from "./HelpMenu";
import UserProfile from "./UserProfile";

import { STATUS } from "../../constants/CommonConstants";

import "../../styles/css/components/commons/top.css";
import NewActions from "./NewActions";

class Top extends Component {
	constructor(props) {
		super(props);

		this.state = {
			contactCount: 0,
			teamChatCount: {}
		};
	}

	async componentDidMount() {
		NotificationService.subscribe("contactCountUpdate", contactCount => {
			// The all count is handled by platfrom, depending on permissions of the user
			let count = contactCount.all;

			this.setState({
				contactCount: count
			});
		});

		NotificationService.subscribe("teamChatCountUpdate", event => {
			this.setState({
				teamChatCount: event.count
			});
		});

		NotificationService.subscribe("locationUpdated", async () => {
			this.forceUpdate();
		});
	}

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	onShowNav = () => {
		NotificationService.publish("toggleNavHide");
	};

	onSupportClicked = () => {
		SupportChatService.showNewMessage();
	};

	onSettingsClicked = () => {
		this.props.history.push("/settings");
	};

	onAskForNotificationPermission = () => {
		let { t } = this.props;
		NotificationService.askNotificationPermission();
		ToastService.error(t("You may need to manually enable notifications in your browser page settings."));
	};

	renderWarningBanner() {
		let location = UserService.getActiveLocation();

		if (!location) {
			return null;
		}

		if (location && location.status === STATUS.inactive) {
			return <div className="dh-top__account-expire">Location Is Inactive</div>;
		}

		if (location && location.status === STATUS.active && location.deactivate_on && location.deactivate_on !== "0000-00-00" && moment(location.deactivate_on)) {
			return <div className="dh-top__account-expire">Account will expire on: {moment(location.deactivate_on).format("ddd, MMM DD")}</div>;
		}

		return null;
	}

	render() {
		let { contactCount, teamChatCount } = this.state;
		let { t } = this.props;

		let isSuperAdminOrCustomerSuccessOrReseller = UserService.isSuperAdminOrCustomerSuccessOrReseller();

		let hasNotificationPermission = NotificationService.hasNotificationPermission();

		let openConvoTip = "Great work you have closed all of your conversations";
		if (contactCount > 0) {
			openConvoTip = t(`You have {{number}} open conversations!`, { number: contactCount });
		}

		let openTeamTip = "No new team chat conversations";
		if (teamChatCount > 0) {
			openTeamTip = t(`You have {{number}} unread team conversations!`, { number: teamChatCount });
		}

		return (
			<div className="dh-top">
				<div className="dh-top__nav-toggle" onClick={this.onShowNav}>
					<Icon.Menu size="20" />
				</div>

				<div className="dh-top__new-button">
					<NewActions />
				</div>
				{this.renderWarningBanner()}
				{isSuperAdminOrCustomerSuccessOrReseller && (
					<div className="dh-top__company-selector">
						<CompanySelector />
					</div>
				)}
				<div className="dh-top__location-selector">
					<LocationSelector />
				</div>
				{!hasNotificationPermission && (
					<div className="dh-top__action" data-tip data-for="dh-top__notifications-enabled" onClick={this.onAskForNotificationPermission}>
						<Icon.BellOff size="16" color="#ec4758" fill="#ec4758" />
					</div>
				)}
				<HelpMenu />
				<div className="dh-top__action" data-tip data-for="dh-top__settings" onClick={this.onSettingsClicked}>
					<Icon.Settings size="16" />
				</div>
				<UserProfile />
				<ReactTooltip id="dh-top__settings" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
					Settings
				</ReactTooltip>
				<ReactTooltip id="dh-top__open-convos" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
					{openConvoTip}
				</ReactTooltip>
				<ReactTooltip id="dh-top__team-messages" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
					{openTeamTip}
				</ReactTooltip>
				<ReactTooltip id="dh-top__notifications-enabled" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
					{t("Enable Notifications")}
				</ReactTooltip>
			</div>
		);
	}
}

export default withRouter(withTranslation(null, { withRef: true })(Top));
