import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import matchSorter from "match-sorter";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import DayPickerInput from "react-day-picker/DayPickerInput";
import { formatDate, parseDate } from "react-day-picker/moment";
import ReactTable from "react-table";
import ReactSwitch from "react-switch";

import PreferenceService from "../../../services/PreferenceService";
import { AnalyticsService } from "../../../services/AnalyticsService";
import GAService from "../../../services/GAService";

import LegacyPage from "../../../components/common/LegacyPage";
import Spinners from "../../../components/common/Spinners";
import NoDataComponent from "../../../components/common/NoDataComponent";

import "../../../styles/css/components/commons/react-table.css";
import "../../../styles/css/components/commons/cs-teamchat.css";

class CSTeamChat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: true,
			showFavoritesOnly: PreferenceService.get("show_favorites") ? true : false,
			data: [],
			filteredData: [],
			searchString: "",
			companyDesc: false,
			totalMessagesDesc: true,
			channelMessagesDesc: true,
			directMessagesDesc: true,
			teamChatUsersDesc: true,
			activeUsersDesc: true,
			start: moment()
				.subtract(30, "days")
				.toDate(),
			end: moment().toDate()
		};
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchTeamChatStats();
	}

	async fetchTeamChatStats(clearCache = false) {
		this.setState({ loading: true, data: [] });
		let { showFavoritesOnly, start, end } = this.state;
		let data = await AnalyticsService.fetchTeamChatCSAnalytics({ favoritesOnly: showFavoritesOnly, startDate: start, endDate: end, clearCache });
		if (!data) {
			// error
			this.setState({ loading: false });
			return;
		}
		this.setState({ loading: false, data });
	}

	handleGeneralSearchOnChange(event) {
		const searchString = event.target.value;
		const filteredData = [{ id: "all", value: searchString }];

		// NOTE: this completely clears any COLUMN filters
		this.setState({ searchString, filteredData });
	}
	toggleFavoritesOnlySwitch() {
		PreferenceService.set("show_favorites", !this.state.showFavoritesOnly);
		this.setState({ showFavoritesOnly: !this.state.showFavoritesOnly }, this.fetchTeamChatStats);
	}
	handleFromChange = start => {
		// Change the from date and focus the "to" input field
		this.setState({ start });
	};
	handleToChange = end => {
		this.setState({ end }, () => {
			this.fetchTeamChatStats();
		});
	};
	render() {
		const { data, showFavoritesOnly, loading, searchString, filteredData, start, end } = this.state;
		const modifiers = { start, end };

		return (
			<LegacyPage>
				<div>
					<div className="row wrapper white-bg page-heading">
						<div className="col-sm-6 col-md-8 col-lg-9">
							<h2>
								Team Chat{" "}
								<span
									id="refresh"
									style={{ marginLeft: "10px", cursor: "pointer", userSelect: "none", transform: "translate(0px, 3px)", position: "absolute" }}
									onClick={() => this.fetchTeamChatStats(true)}
								>
									<Icon.RefreshCcw />
								</span>
							</h2>
							<input
								style={{ width: "400px" }}
								type="text"
								placeholder="Filter Team Chat..."
								className="form-control"
								value={searchString}
								onChange={e => this.handleGeneralSearchOnChange(e)}
							/>
						</div>
						<div
							className="col-sm-6 col-md-4 col-lg-3"
							style={{
								display: "flex",
								justifyContent: "flex-end",
								alignItems: "center",
								marginTop: "20px",
								paddingRight: "20px"
							}}
						>
							<div>
								<b>All</b>
							</div>
							<div style={{ marginRight: "5px", marginLeft: "5px" }}>
								<ReactSwitch
									id="show_favorites_only"
									onChange={() => this.toggleFavoritesOnlySwitch()}
									checked={showFavoritesOnly}
									uncheckedIcon={false}
									checkedIcon={false}
									onColor="#4A90E2"
									type="text"
								/>
							</div>
							<div>
								<b>Favourites</b>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="form-group CSTeamChat__date-range">
								<label>Date Range</label>
								<div className="input-group" id="datepicker">
									<div className="InputFromTo">
										<DayPickerInput
											value={start}
											placeholder=" From"
											format="LL"
											formatDate={formatDate}
											parseDate={parseDate}
											dayPickerProps={{
												selectedDays: [start, { from: start, to: end }],
												disabledDays: { after: end },
												toMonth: end,
												modifiers,
												numberOfMonths: 2,
												onDayClick: () => this.to.getInput().focus()
											}}
											onDayChange={start => this.handleFromChange(start)}
										/>{" "}
										<span className="InputFromTo-to">
											<DayPickerInput
												ref={el => (this.to = el)}
												value={end}
												placeholder=" To"
												format="LL"
												formatDate={formatDate}
												parseDate={parseDate}
												dayPickerProps={{
													selectedDays: [start, { from: start, to: end }],
													disabledDays: { before: start },
													modifiers,
													month: start,
													fromMonth: start,
													numberOfMonths: 2
												}}
												onDayChange={this.handleToChange}
											/>
										</span>
									</div>
								</div>{" "}
							</div>
						</div>
					</div>

					<div className="wrapper wrapper-content">
						<div className="ibox">
							<div className="row">
								<div className="col-sm-12">
									<div className="ibox-content">
										<ReactTable
											data={data}
											defaultSorted={[
												{
													id: "company",
													desc: false
												}
											]}
											onSortedChange={sorted => {
												if (sorted.length !== 1) {
													return;
												}
												this.setState({ [sorted[0].id + "Desc"]: sorted[0].desc });
											}}
											filtered={filteredData}
											onFilteredChange={filteredData => this.setState({ filteredData })}
											columns={[
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Company {this.state.companyDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "company_name",
													className: "DHReactTable__cell",
													id: "company",
													Cell: row => <span className="DHReactTable__row">{row.value}</span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Total Messages {this.state.totalMessagesDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}{" "}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "total_messages",
													className: "DHReactTable__cell",
													id: "totalMessages",
													Cell: row => <span className="DHReactTable__row">{row.value}</span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Group Messages {this.state.channelMessagesDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "channel_messages",
													className: "DHReactTable__cell",
													id: "channelMessages",
													Cell: row => <span className="DHReactTable__row">{row.value}</span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Direct Messages {this.state.directMessagesDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "dm_messages",
													className: "DHReactTable__cell",
													id: "directMessages",
													Cell: row => <span className="DHReactTable__row">{row.value}</span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Total Users {this.state.teamChatUsersDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "teamchat_users",
													className: "DHReactTable__cell",
													id: "teamChatUsers",
													Cell: row => <span className="DHReactTable__row">{row.value}</span>
												},
												{
													Header: () => (
														<div className="DHReactTable__header--container">
															Active Users{" "}
															<span data-tip data-for="active-users-tooltip" style={{ marginLeft: 3 }}>
																<Icon.Info size="16" />
															</span>{" "}
															{this.state.activeUsersDesc ? <Icon.ChevronDown size="20" /> : <Icon.ChevronUp size="20" />}
															<ReactTooltip
																className="mb-react-tooltip"
																arrowColor="#333"
																id="active-users-tooltip"
																type="info"
																effect="solid"
																place="left"
																multiline="true"
															>
																Number of users that have sent a message within the specified date range
															</ReactTooltip>
														</div>
													),
													headerClassName: "DHReactTable__row--header",
													accessor: "active_users",
													className: "DHReactTable__cell",
													id: "activeUsers",
													Cell: row => <span className="DHReactTable__row">{row.value}</span>
												},
												{
													Header: "All",
													id: "all",
													width: 0,
													headerClassName: "hide",
													resizable: false,
													sortable: false,
													Filter: () => {},
													getProps: () => {
														return {
															// style: { padding: "0px"}
														};
													},
													filterMethod: (filter, rows) => {
														// using match-sorter
														// it will take the content entered into the "filter"
														// and search for it in EITHER the firstName or lastName
														const result = matchSorter(rows, filter.value, {
															keys: ["company"],
															threshold: matchSorter.rankings.CONTAINS
														});
														return result;
													},
													filterAll: true
												}
											]}
											LoadingComponent={() => (
												<div className="Common__spinnerdiv--center">
													<Spinners loading={loading} type="tail-fade" size="120px" />
												</div>
											)}
											NoDataComponent={() => <NoDataComponent loading={loading} />}
											showPagination={data.length > 10}
											PreviousComponent={props => (
												<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
													<Icon.ChevronLeft style={{ paddingTop: "4px" }} size="20" />
												</button>
											)}
											NextComponent={props => (
												<button {...props} className="btn btn-default btn-outline btn-sm pagination-item">
													<Icon.ChevronRight style={{ paddingTop: "4px" }} size="20" />
												</button>
											)}
											defaultPageSize={10}
											className="-striped -highlight"
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LegacyPage>
		);
	}
}

export default withRouter(CSTeamChat);
