import UserService from "./UserService";
import Kichiri from "./KichiriService";
import axios from "axios";
import { MEDIUM } from "../constants/Messenger";
import AppConfig from "../config/app/web-app.config.js";

let count = {
	all: 0,
	unassigned: 0,
	assignedToMe: 0,
	locations: {}
};

const ContactService = {
	/**
	 * Retreives a contact via a contact id
	 *
	 * @param {Number} contactId
	 * @return {Promise}
	 */
	async getContact(contactId) {
		let user = UserService.get();

		try {
			let response = await Kichiri.contacts.getContactById(
				{
					contactId: contactId
				},
				{},
				user.auth_token
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Find a contact via contact info
	 *
	 * @param {Number} locationId required
	 * @param {Number} contactId
	 * @param {String} phone
	 * @param {String} email
	 *
	 * @return {Promise}
	 */
	async findContact({ locationId, contactId, phone, email }) {
		let user = UserService.get();

		try {
			let response = await Kichiri.contacts.findContact({}, { locationId, contactId, phone, email }, user.auth_token);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update a contact
	 *
	 * @param {Object} contactDetails
	 * @return {Promise}
	 */
	async updateContact(contactDetails) {
		let user = UserService.get();

		try {
			let response = await Kichiri.contacts.update(contactDetails, {}, user.auth_token);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Get contacts from a location
	 * @param {Integer} locationId
	 * @param {Object} filters An object where the properties are different filters on contacts
	 * @param {Array[String]} status Array of contacts status'
	 * @param {String} sortField
	 * @param {String} sortOrder
	 * @param {Integer} limit
	 *
	 */
	async getContactsByLocation({ locationId, filters = null, status, sortField, sortOrder, limit }) {
		try {
			if (!locationId) {
				locationId = UserService.getActiveLocation().id;
			}
			let query = {};
			if (filters) {
				query.filters = filters;
			}
			if (status) {
				query.status = JSON.stringify(status);
			}
			if (sortField) {
				query.sortField = sortField;
			}
			if (sortOrder) {
				query.sortOrder = sortOrder;
			}
			if (limit) {
				query.limit = limit;
			}
			let response = await Kichiri.location.getContacts({ locationId }, query, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			throw error;
		}
	},

	/**
	 * Cache the contact open conversation count
	 *
	 * @param {Object} contactCount
	 */
	cacheContactCount(contactCount) {
		count = contactCount;
	},

	/**
	 * Get cached contact count
	 *
	 * @returns {Object}
	 */
	getCachedContactCount() {
		return count;
	},

	/**
	 * Add a note for a contact
	 * @param {Number} contactId
	 * @param {Number} note
	 * @returns True on success, else null
	 */
	async addContactNote({ contactId, note }) {
		try {
			let user = UserService.get();
			await Kichiri.contacts.addContactNote({ contactId, note }, {}, user.auth_token);
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch notes for a contact
	 * @param {Number} contactId
	 * @param {Number} locationId
	 * @returns {Object}
	 */
	async fetchContactNotes({ contactId, locationId }) {
		try {
			let user = UserService.get();
			let response = await Kichiri.contacts.fetchNotes({ locationId, contactId }, {}, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch note for a contact
	 * @param {Number} contactId
	 * @param {Number} noteId
	 * @returns {Object}
	 */
	async fetchContactNote({ contactId, noteId }) {
		try {
			let user = UserService.get();
			let response = await Kichiri.contacts.fetchNote({ contactId, noteId }, {}, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a note for a contact
	 * @param {Number} contactId
	 * @param {Number} noteId
	 * @param {String} content
	 * @returns
	 */
	async updateContactNote({ contactId, noteId, content }) {
		try {
			let user = UserService.get();
			let response = await Kichiri.contacts.updateNote({ contactId, noteId, noteContent: content }, {}, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Delete a note for a contact
	 * @param {Number} contactId
	 * @param {Number} noteId
	 * @returns
	 */
	async deleteContactNote({ contactId, noteId }) {
		try {
			let user = UserService.get();
			let response = await Kichiri.contacts.deleteNote({ contactId, noteId }, {}, user.auth_token);
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Delete a contact
	 * @param {Number} id
	 */
	async deleteContact(id) {
		try {
			await Kichiri.contacts.delete({ contactId: id }, {}, UserService.getAuthToken());
			return true;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Fetch a contacts custom fields
	 * @param {Number} contactId
	 * @returns
	 */
	async getContactCustomFields({ contactId }) {
		try {
			let response = await Kichiri.contacts.fetchCustomFields({ contactId }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Update a contact's custom fields
	 * @param {Number} contactId
	 * @param {Array} customFields
	 * @returns
	 */
	async updateContactCustomFields({ contactId, customFields }) {
		try {
			let response = await Kichiri.contacts.updateCustomFields({ contactId, customFields }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Merge a contact with a secondary contact.
	 *
	 * @param {Number} contactId
	 * @param {Number} secondaryContactId
	 * @param {Array} fields Fields from the secondary contact that we want to transfer to the primary contact
	 *
	 * @returns {Object} Updated contact
	 */
	async mergeContact({ contactId, secondaryContactId, fields }) {
		try {
			let response = await Kichiri.contacts.mergeContact({ contactId, secondaryContactId, fields }, {}, UserService.getAuthToken());
			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Get a pdf of the conversation with this contact
	 * @param {Number} contactId
	 * @param {Boolean} allMediums
	 * @param {Boolean} allTime
	 * @param {Array/Stirng} mediums
	 * @param {String} startDate
	 * @param {String} endDate
	 *
	 * @returns {Buffer}
	 */
	async downloadConversation({ contactId, allMediums, allTime, mediums, startDate, endDate }) {
		try {
			let response = await axios({
				url: `${AppConfig.API_SERVER}/api/contacts/${contactId}/download_conversation`,
				method: "GET",
				params: { contactId, allMediums, allTime, mediums, startDate, endDate },
				responseType: "arraybuffer",
				headers: {
					Authorization: UserService.getAuthToken()
				}
			});

			return response.data;
		} catch (error) {
			console.log(error);
		}
		return null;
	},

	/**
	 * Can view a contact's messages
	 * @param {Object} contact
	 * @returns
	 */
	canViewContactsMessages(contact) {
		let user = UserService.get();

		if (!contact) {
			return false;
		}

		// View messages assigned to others
		if (contact.assigned_user_id !== user.id && !user.GroupPermission.view_messages_assigned_to_others) {
			return false;
		}

		// View unassigned messages
		if (contact.assigned_user_id === 0 && !user.GroupPermission.view_unassigned_messages) {
			return false;
		}

		// View your own customer messages
		if (contact.assigned_user_id === user.id && !user.GroupPermission.view_customer_messages) {
			return false;
		}

		return true;
	},

	getDownloadableMediums: function() {
		let mediums = { ...MEDIUM };
		delete mediums.demandhub;
		delete mediums.unknown;

		return Object.values(mediums);
	},

	/**
	 * Bulk update data for contacts
	 *
	 * @param {String} status
	 * @param {Number} inboxId
	 * @param {Object} flags
	 * @param {Array} contactIds
	 *
	 * @returns {Boolean}
	 *
	 */
	bulkUpdateContacts: async function({ status, inboxId, flags, contactIds }) {
		try {
			let response = await Kichiri.contacts.bulkUpdateContacts({ status, inboxId, flags, contactIds }, {}, UserService.getAuthToken());

			if (!response) {
				return false;
			}

			return true;
		} catch (error) {
			throw error;
		}
	}
};

export default ContactService;
