import React, { Component } from "react";
import * as Icon from "react-feather";
import ReactTooltip from "react-tooltip";
import ContentLoader from "react-content-loader";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import TemplateService from "../../services/TemplateService";
import NotificationService from "../../services/NotificationService";
import ToastService from "../../services/ToastService";

import { KEYS, MEDIA_TYPES } from "../../constants/Messenger";

import SearchInput from "./SearchInput";
import EditTemplateModal from "./EditTemplateModal";

import "../../styles/css/components/commons/templates.css";

class Templates extends Component {
	constructor(props) {
		super(props);

		this.state = {
			templates: [],
			loading: false,
			filteredTemplates: [],
			searchTerm: "",
			selectedIndex: 0,
			showEditTemplateModal: false,
			editTemplateId: null,
			recentlyUsedTemplates: []
		};

		this.searchInput = null;
		this.currentSelectedTemplate = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	componentDidMount = async () => {
		NotificationService.subscribeOnce("locationChanged", "commonTemplates", async () => {
			await this.resetComponent();
		});

		await this.resetComponent();
	};

	async componentDidUpdate(prevProps) {
		const { show, contactId } = prevProps;

		if (this.props.show !== show && show) {
			if (this.searchInput) {
				setTimeout(() => {
					this.searchInput.focus();
				}, 200);
			}
		}

		if (this.props.contactId !== contactId) {
			await this.fetchTemplates();
		}
	}

	resetComponent = async () => {
		await this.update({
			templates: [],
			filteredTemplates: [],
			searchTerm: "",
			selectedIndex: 0,
			showEditTemplateModal: false,
			editTemplateId: null,
			recentlyUsedTemplates: []
		});

		// Fetch the relevant templates
		await this.fetchTemplates();
	};

	getRecentlyUsedTemplates = async () => {
		let { templates } = this.state;
		let recentlyUsedTemplateIds = await TemplateService.getRecentlyUsedTemplates();

		let recentlyUsedTemplates = [];

		for (let t of templates) {
			if (recentlyUsedTemplateIds.includes(t.id)) {
				recentlyUsedTemplates.push(t);
			}
		}

		await this.update({ recentlyUsedTemplates });
	};

	updateTemplate = async ({ templateId, isFavourite }) => {
		let { templates } = this.state;
		let { t } = this.props;

		let updatedTemplates = templates.slice();

		for (let t of updatedTemplates) {
			if (t.id === templateId) {
				t.is_favourite = isFavourite;
			}
		}

		await this.update({ templates: updatedTemplates });

		// Make call to update template
		let response = await TemplateService.update({ templateId, isFavourite });

		if (!response) {
			ToastService.info(t(`An error occurred trying to save the template.`));
		}

		await this.fetchTemplates();
	};

	fetchTemplates = async () => {
		let locationId = UserService.getActiveLocation().id;
		let { templateType, replaceMessageVariables, contactId, reviewRequestId, overwriteReplacements } = this.props;

		await this.update({ loading: true });

		let templates = await TemplateService.fetchTemplates({
			locationId: locationId,
			types: templateType ? [templateType] : ["general"],
			replaceMessageVariables,
			contactId,
			reviewRequestId,
			overwriteReplacements,
			includeMedia: true,
			sortField: "isFavourite",
			sortOrder: "asc"
		});

		if (!templates) {
			await this.update({ loading: false });
			return;
		}

		await this.update({
			templates: templates,
			filteredTemplates: templates,
			loading: false
		});

		// Get recently used templates from localstorage
		this.getRecentlyUsedTemplates();
	};

	onClose = () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onSearchChange = async value => {
		let { templates, searchTerm } = this.state;

		await this.update({ searchTerm: value });

		const filteredTemplates = templates.filter(template => {
			if (template.name.toLowerCase().includes(searchTerm.toLowerCase())) {
				return true;
			} else if (template.msg_text.toLowerCase().includes(searchTerm.toLowerCase())) {
				return true;
			}
			return false;
		});
		await this.update({ filteredTemplates, selectedIndex: 0 });
	};

	onEnter = async e => {
		if (e.keyCode === KEYS.up || e.keyCode === KEYS.down || e.keyCode === KEYS.enter || e.keyCode === KEYS.tab) {
			e.preventDefault();
			await this.triggerKeyboardEvent(e.keyCode);
		}
	};

	triggerKeyboardEvent = async keyCode => {
		let { selectedIndex, filteredTemplates } = this.state;

		if (keyCode === KEYS.enter || keyCode === KEYS.tab) {
			let template = filteredTemplates[selectedIndex];
			if (template) {
				this.onTemplateSelected(template);
			}
			return;
		}

		if (keyCode === KEYS.down) {
			selectedIndex++;
		} else if (keyCode === KEYS.up) {
			selectedIndex--;
		}

		if (selectedIndex < 0) {
			selectedIndex = 0;
		} else if (selectedIndex >= filteredTemplates.length) {
			selectedIndex = filteredTemplates.length - 1;
		}

		await this.update({
			selectedIndex
		});

		if (this.currentSelectedTemplate) {
			this.currentSelectedTemplate.scrollIntoView(false);
		}
	};

	onTemplateSelected = async template => {
		// Register the template and get the new list of recent templates
		TemplateService.registerRecentlyUsedTemplate(template.id);

		this.getRecentlyUsedTemplates();

		if (this.props.onSelect) {
			await this.props.onSelect(template);
		}
	};

	toggleShowEditTemplateModal = async showEditContactModal => {
		await this.update({ showEditTemplateModal: showEditContactModal });
		if (this.props.onToggleShowEditTemplateModal) {
			this.props.onToggleShowEditTemplateModal(this.state.showEditTemplateModal);
		}
	};

	onCreateTemplate = async () => {
		await this.update({ editTemplateId: 0 });
		await this.toggleShowEditTemplateModal(true);
	};

	onEditTemplate = async template => {
		await this.update({ editTemplateId: template.id });
		await this.toggleShowEditTemplateModal(true);
	};

	onHideEditModal = async () => {
		await this.update({ editTemplateId: 0 });
		await this.toggleShowEditTemplateModal(false);
	};

	onSubmitEditModal = async () => {
		await this.toggleShowEditTemplateModal(false);
		await this.update({ editTemplateId: 0 });

		// Fetch the fresh templates
		await this.fetchTemplates();
	};

	onToggleFavouriteTemplate = async template => {
		// Extract it's favourite status
		let isFavourite = !template.is_favourite;
		await this.updateTemplate({ templateId: template.id, isFavourite });
	};

	renderMediaIcon(media) {
		if (media.type === MEDIA_TYPES.video) {
			return <Icon.Film size="14" />;
		} else if (media.type === MEDIA_TYPES.image) {
			return <Icon.Image size="14" />;
		}

		return <Icon.File size="14" />;
	}

	renderTemplateListItem(template, index) {
		let { showEditAction, showFavourites } = this.props;
		let { selectedIndex, searchTerm } = this.state;

		// Get permissions for which tabs we should show
		let user = UserService.get();

		let allowUpdate = user.GroupPermission.update_templates;

		let templateId = template.id;
		let templateName = template.name;
		let templateMessageText = template.msg_text;

		let isCurrentTemplateSelected = selectedIndex === index;

		let hasMedia = template.Media && template.Media.length > 0;
		let templateMedia = hasMedia ? template.Media[0] : null;
		let isFavourite = template.is_favourite ? true : false;

		return (
			<div
				ref={ref => {
					if (isCurrentTemplateSelected) {
						this.currentSelectedTemplate = ref;
					}
				}}
				key={templateId}
				className={`dh-templates__list__item ${isCurrentTemplateSelected && searchTerm ? "dh-templates__list__item--selected" : ""}`}
			>
				<div className="dh-templates__list__item__content" onClick={() => this.onTemplateSelected(template)}>
					<div className={`dh-templates__list__item__content__top`}>
						<div className="dh-templates__list__item__content__name__text">{templateName}</div>
						{hasMedia && <div className="dh-templates__list__item__content__name__icon">{this.renderMediaIcon(templateMedia)}</div>}
					</div>
					<div className={`dh-templates__list__item__content__bottom`}>
						<div className="dh-templates__list__item__content__text" title={templateMessageText}>
							{templateMessageText}
						</div>
					</div>
				</div>
				<div className="dh-templates__list__item-actions">
					{showFavourites && allowUpdate && (
						<>
							<ReactTooltip
								id={`favourite-template-tooltip-${templateId}`}
								className="mb-react-tooltip"
								arrowColor="#333"
								type="info"
								effect="solid"
								place="left"
							>
								<div className="text-left">{isFavourite ? "Unfavourite Template" : "Favourite Template"}</div>
							</ReactTooltip>
							<div
								data-tip
								data-for={`favourite-template-tooltip-${templateId}`}
								className="dh-templates__list__item-action"
								onClick={() => this.onToggleFavouriteTemplate(template)}
							>
								{isFavourite ? <Icon.Star color="#60A9FF" fill="#60A9FF" size="12" /> : <Icon.Star size="12" />}
							</div>
						</>
					)}
					{showEditAction && allowUpdate && (
						<>
							<ReactTooltip id="edit-template-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="left">
								<div className="text-left">Edit</div>
							</ReactTooltip>
							<div
								data-tip
								data-for="edit-template-tooltip"
								className="dh-templates__list__item-action fnctst-edit-template"
								onClick={() => this.onEditTemplate(template)}
							>
								<Icon.Edit2 size="12" />
							</div>
						</>
					)}
				</div>
			</div>
		);
	}

	renderTemplateList() {
		let { showRecentlyUsed, t } = this.props;
		let { recentlyUsedTemplates, templates, filteredTemplates, searchTerm, loading } = this.state;

		if (loading) {
			return (
				<div className="dh-templates__list-loading">
					<ContentLoader height={"100%"} width={"100%"}>
						<rect x="0" y="0" rx="5" ry="5" width="100%" height="60" />
						<rect x="0" y="65" rx="5" ry="5" width="100%" height="60" />
						<rect x="0" y="130" rx="5" ry="5" width="100%" height="60" />
						<rect x="0" y="195" rx="5" ry="5" width="100%" height="60" />
						<rect x="0" y="260" rx="5" ry="5" width="100%" height="60" />
						<rect x="0" y="325" rx="5" ry="5" width="100%" height="60" />
					</ContentLoader>
				</div>
			);
		}

		// If we have something in the search bar, show the filtered list of items
		let templatesToRender = searchTerm ? filteredTemplates : templates;

		// If we have showRecentlyUsed prop is enabled and nothing is in the search bar then also show the recently used templatesß
		let shouldShowRecentlyUsedTemplates = showRecentlyUsed && !searchTerm;

		return (
			<div className="dh-templates__list">
				{shouldShowRecentlyUsedTemplates && (
					<>
						<div className="dh-templates__list__header">{t("RECENTLY USED")}</div>
						{recentlyUsedTemplates.map((template, index) => this.renderTemplateListItem(template, index))}
						<div className="dh-templates__list__divider" />
					</>
				)}
				{templatesToRender.map((template, index) => this.renderTemplateListItem(template, index))}
			</div>
		);
	}

	render() {
		let { templateType, typeEditable, showEditAction, t } = this.props;
		let { showEditTemplateModal, editTemplateId } = this.state;

		// Get permissions for which tabs we should show
		let user = UserService.get();

		let allowCreate = user.GroupPermission.create_templates;

		return (
			<>
				<div className="dh-templates">
					<div className="dh-templates__header">
						<div className="dh-templates__header__title">{t("Templates")}</div>
						{showEditAction && allowCreate && (
							<>
								<ReactTooltip id="create-template-tooltip" className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="bottom">
									<div className="text-left">{t("Create Template")}</div>
								</ReactTooltip>
								<div data-tip data-for="create-template-tooltip" className="dh-templates__header__action" onClick={this.onCreateTemplate}>
									<Icon.PlusCircle size="15" />
								</div>
							</>
						)}

						<div className="dh-templates__header__x" onClick={this.onClose}>
							<Icon.X size="18" />
						</div>
					</div>
					<div className="dh-templates__search">
						<SearchInput
							ref={input => {
								this.searchInput = input;
							}}
							placeholder={t("Search by template name or text...")}
							onChange={this.onSearchChange}
							onKeyDown={this.onEnter}
							debounce={false}
						/>
					</div>
					{this.renderTemplateList()}
				</div>
				{showEditAction && (
					<EditTemplateModal
						show={showEditTemplateModal}
						templateId={editTemplateId}
						templateType={templateType ? templateType : "general"}
						typeEditable={typeEditable}
						onHide={() => this.onHideEditModal()}
						onSubmit={() => this.onSubmitEditModal()}
					/>
				)}
			</>
		);
	}
}

export default withTranslation(null, { withRef: true })(Templates);
