import React, { Component } from "react";
import * as Icon from "react-feather";
import ContentLoader from "react-content-loader";
import Highlighter from "react-highlight-words";
import { PoseGroup } from "react-pose";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import config from "../../config/app/web-app.config";

import SearchInput from "../../components/common/SearchInput";

import UserService from "../../services/UserService";
import CompanyService from "../../services/CompanyService";
import NotificationService from "../../services/NotificationService";
import UtilityService from "../../services/UtilityService";

import { ContextMenu, KEYS } from "../../constants/Messenger";

import "./company-selector.css";

class CompanySelector extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchedCompanies: [],
			recentlyUsedCompanies: [],
			searchTerm: "",
			selectedIndex: 0,
			showList: false,
			loading: false
		};

		this.searchInput = null;
		this.currentSelectedCompany = null;
		this.companySelectorModal = null;
	}

	update = o => {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	};

	async componentDidMount() {
		document.addEventListener("mousedown", this.onHandleClick, false);

		NotificationService.subscribeOnce("companyChanged", "companySelector_component", () => {
			this.resetComponent();
		});

		await this.resetComponent();
	}

	componentWillUnmount() {
		document.removeEventListener("mousedown", this.onHandleClick, false);
	}

	async resetComponent() {
		await this.update({
			searchedCompanies: [],
			recentlyUsedCompanies: [],
			searchTerm: "",
			selectedIndex: 0,
			showList: false
		});

		await this.getRecentlyUsedCompanies();
	}

	getRecentlyUsedCompanies = async () => {
		let recentlyUsedCompanies = await CompanyService.getRecentlyUsedCompanies();
		recentlyUsedCompanies = recentlyUsedCompanies.reverse();

		await this.update({ recentlyUsedCompanies });
	};

	onSearchChange = async value => {
		if (!value) {
			await this.update({ searchedCompanies: [], selectedIndex: 0, searchTerm: "" });
			return;
		}

		let query = { searchTerm: value, limit: 30, isAdvanceFetch: true };

		// If we're searching a number, it's a locationID or companyID
		if (/^-?\d+$/.test(value)) {
			query.locationId = value;
			query.companyId = value;
		}

		let searchedCompanies = await CompanyService.fetchCompanies(query);

		// Only companies with locations already created are allowed to be selected
		if (searchedCompanies) {
			searchedCompanies = searchedCompanies.filter(company => company.locations && company.locations.length > 0);
		}

		await this.update({ searchedCompanies, selectedIndex: 0, searchTerm: value });
	};

	onEnter = async e => {
		if (e.keyCode === KEYS.up || e.keyCode === KEYS.down || e.keyCode === KEYS.enter || e.keyCode === KEYS.tab) {
			e.preventDefault();
			await this.triggerKeyboardEvent(e.keyCode);
		}
	};

	triggerKeyboardEvent = async keyCode => {
		let { selectedIndex, searchedCompanies } = this.state;

		if (keyCode === KEYS.enter || keyCode === KEYS.tab) {
			let company = searchedCompanies[selectedIndex];
			if (company) {
				let companyName = company.name;
				let companyId = company.id;
				await this.onSelect(companyId, companyName);
			}
			return;
		}

		if (keyCode === KEYS.down) {
			selectedIndex++;
		} else if (keyCode === KEYS.up) {
			selectedIndex--;
		}

		if (selectedIndex < 0) {
			selectedIndex = searchedCompanies.length - 1;
		} else if (selectedIndex > searchedCompanies.length - 1) {
			selectedIndex = 0;
		}

		await this.update({
			selectedIndex
		});

		if (this.currentSelectedCompany) {
			this.currentSelectedCompany.scrollIntoView(false);
		}
	};

	async onSelect(companyId, companyName) {
		if (!companyId || !companyName) {
			return;
		}

		await this.update({ loading: true });

		// Register the company and get the new list of recent companies
		CompanyService.registerRecentlyUsedCompany(companyId, companyName);

		await this.getRecentlyUsedCompanies();

		await CompanyService.setCompanyAndLocationAsAdmin(companyId, companyName);

		await this.update({ loading: false });
		await this.resetComponent();
	}

	goToDemandHub = () => {
		this.onSelect(config.DEMANDHUB.COMPANY.ID, config.DEMANDHUB.COMPANY.NAME);
	};

	goToDemandHubTest = () => {
		this.onSelect(config.DEMANDHUB_TEST.COMPANY.ID, config.DEMANDHUB_TEST.COMPANY.NAME);
	};

	goToCompanyList = () => {
		this.props.history.push("/companies");
		this.resetComponent();
	};

	onCatClicked = () => {
		this.goToDemandHub();
	};

	clearRecentlyUsed = async () => {
		CompanyService.clearRecentlyUsedCompanies();
		await this.getRecentlyUsedCompanies();
	};

	onHandleClick = async e => {
		let { showList } = this.state;

		if (this.companySelectorModal && this.companySelectorModal.contains(e.target)) {
			return;
		}

		if (this.contextContainer && this.contextContainer.contains && this.contextContainer.contains(e.target) && !showList) {
			await this.showModal();
		} else if (this.companySelectorModal && this.companySelectorModal.contains) {
			await this.hideModal();
		}
	};

	showModal = async () => {
		await this.update({ showList: true });
		if (this.searchInput) {
			this.searchInput.focus();
		}
	};

	hideModal = async () => {
		await this.update({ showList: false, searchTerm: "", selectedIndex: 0 });
	};

	renderRecentCompanyListItem(company, index) {
		let { t } = this.props;

		let companyId = company.id;
		let companyName = company.name;

		let tipColour = UtilityService.stringToColour(companyName + companyId);

		return (
			<div key={companyId} className={`company-selector__modal__list__item`}>
				<div className="company-selector__modal__list__item__content" onClick={() => this.onSelect(companyId, companyName)}>
					<div className={`company-selector__modal__list__item__content__top`}>
						<div className="company-selector__modal__list__item__content__name__text">{companyName}</div>
						<div className="company-selector__modal__list__item__content__colour" style={{ backgroundColor: tipColour }} />
					</div>
					<div className={`company-selector__modal__list__item__content__bottom`}>
						<div className="company-selector__modal__list__item__content__text">
							{t("Company ID")}: {companyId}
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderSearchedCompanyListItem(company, index) {
		let { selectedIndex, searchTerm } = this.state;
		let { t } = this.props;

		let companyId = company.id;
		let companyName = company.name;

		let isCurrentCompanySelected = selectedIndex === index;
		let tipColour = UtilityService.stringToColour(companyName + companyId);
		let companyIdLabel = `${t("Company ID")}: ${companyId}`;

		// If it's a number then we will do some more checks
		let locationMatches = [];
		if (/^-?\d+$/.test(searchTerm) && company.locations.length > 0) {
			for (let i = 0; i < company.locations.length; i++) {
				let location = company.locations[i];
				let locationId = location.id;

				// If our ID includes the search term
				if (locationId.toString().includes(searchTerm)) {
					locationMatches.push(location);
				}
			}
		}

		return (
			<div
				ref={ref => {
					if (isCurrentCompanySelected) {
						this.currentSelectedCompany = ref;
					}
				}}
				key={companyId}
				className={`company-selector__modal__list__item ${isCurrentCompanySelected && searchTerm ? "company-selector__modal__list__item--selected" : ""}`}
			>
				<div className="company-selector__modal__list__item__content" onClick={() => this.onSelect(companyId, companyName)}>
					<div className={`company-selector__modal__list__item__content__top`}>
						<div className="company-selector__modal__list__item__content__name__text">{companyName}</div>
						<div className="company-selector__modal__list__item__content__colour" style={{ backgroundColor: tipColour }} />
					</div>
					<div className={`company-selector__modal__list__item__content__bottom`}>
						<div className="company-selector__modal__list__item__content__text">
							<Highlighter searchWords={[searchTerm]} autoEscape={true} textToHighlight={companyIdLabel} />
						</div>
						{locationMatches.length > 0 && (
							<div className="company-selector__modal__list__item__content__locations">
								<span>{t("Locations")}: </span>
								{locationMatches.map((location, index) => {
									let locationId = location.id;
									let locationName = location.name;

									let locationBlurb = `${locationName} (${locationId})`;

									if (index !== locationMatches.length - 1) {
										locationBlurb += ", ";
									}

									return <Highlighter searchWords={[searchTerm]} autoEscape={true} textToHighlight={locationBlurb} />;
								})}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}

	renderRecentList() {
		let { recentlyUsedCompanies } = this.state;
		let { t } = this.props;

		let isSuperAdmin = UserService.isSuperAdmin();

		let hasRecentCompanies = recentlyUsedCompanies.length > 0;

		return (
			<>
				{hasRecentCompanies && (
					<>
						<div className="company-selector__modal__list__header">
							{t("RECENTLY USED")}
							<div className="company-selector__modal__list__header__action" onClick={this.goToDemandHub}>
								<Icon.Home size={12} />
							</div>
							{isSuperAdmin && (
								<div
									className="company-selector__modal__list__header__action company-selector__modal__list__header__action-hidden"
									onClick={this.goToDemandHubTest}
								>
									<Icon.GitHub size={12} />
								</div>
							)}
							<div
								className="company-selector__modal__list__header__action company-selector__modal__list__header__action-hidden"
								onClick={this.clearRecentlyUsed}
							>
								<Icon.Trash2 size={12} />
							</div>{" "}
							<div
								className="company-selector__modal__list__header__action company-selector__modal__list__header__action-hidden"
								onClick={this.goToCompanyList}
							>
								<Icon.List size={12} />
							</div>
						</div>
						{recentlyUsedCompanies.map((company, index) => this.renderRecentCompanyListItem(company, index))}
					</>
				)}
				{!hasRecentCompanies && (
					<img
						className="company-selector__modal__list__image__placeholder"
						src="https://cdn.demandhub.co/web-app/assets/cat.svg"
						alt="Satbir Was here"
						onClick={this.onCatClicked}
					/>
				)}
			</>
		);
	}

	renderSearchedCompanyList() {
		let { searchedCompanies } = this.state;

		if (searchedCompanies.length < 1) {
			return null;
		}

		return searchedCompanies.map((company, index) => this.renderSearchedCompanyListItem(company, index));
	}

	renderCompanies() {
		let { loading, searchTerm } = this.state;

		let renderSearchResults = searchTerm ? true : false;

		return (
			<div className="company-selector__modal__list">
				{loading && this.renderLoading()}
				{!loading && !renderSearchResults && this.renderRecentList()}
				{!loading && renderSearchResults && this.renderSearchedCompanyList()}
			</div>
		);
	}

	renderLoading() {
		return (
			<div className="company-selector__modal__loading">
				<ContentLoader height={480} width={"100%"}>
					{/* The rows */}
					<rect x="0" y="40" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="105" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="170" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="235" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="300" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="365" rx="5" ry="5" width="100%" height="60" />
					<rect x="0" y="430" rx="5" ry="5" width="100%" height="60" />
				</ContentLoader>
			</div>
		);
	}

	renderModal() {
		let { t } = this.props;

		return (
			<ContextMenu key="container" className="company-selector__modal" ref={ref => (this.companySelectorModal = ref)}>
				<div className="company-selector__modal__search">
					<SearchInput
						id="company-selector__search_input"
						ref={input => {
							this.searchInput = input;
						}}
						placeholder={t("Search by company name...")}
						onChange={this.onSearchChange}
						onKeyDown={this.onEnter}
						debounce={false}
					/>
				</div>
				{this.renderCompanies()}
			</ContextMenu>
		);
	}

	render() {
		let { showList } = this.state;

		let company = UserService.getActiveCompany();

		let companyId = company.id;
		let companyName = company.name;
		let isSuperAdminOrCustomerSuccess = UserService.isSuperAdminOrCustomerSuccess();

		if (!companyId) {
			return;
		}

		return (
			<div ref={ref => (this.contextContainer = ref)} className="company-selector">
				<div className="company-selector__icon">
					<Icon.Briefcase size="16" />
				</div>
				<div className="company-selector__name" onClick={this.showModal}>
					{isSuperAdminOrCustomerSuccess ? `(${companyId}) ` : ""}
					{companyName}
				</div>
				<PoseGroup>{showList && this.renderModal()}</PoseGroup>
			</div>
		);
	}
}

export default withTranslation(null, { withRef: true })(withRouter(CompanySelector));
