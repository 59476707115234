import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { withTranslation } from "react-i18next";
import JSONInput from "react-json-editor-ajrm";
import locale from "react-json-editor-ajrm/locale/en";
import moment from "moment";

import Kichiri from "../../services/KichiriService";
import UserService from "../../services/UserService";
import GAService from "../../services/GAService";
import ToastService from "../../services/ToastService";
import CompanyService from "../../services/CompanyService";

import LegacyPage from "../../components/common/LegacyPage";
import Spinners from "../../components/common/Spinners";
import Alert from "../../components/common/Alert";

import "../../styles/css/components/commons/react-table.css";
import "../../styles/css/scenes/companies.css";
import "../../styles/css/scenes/manage-compare-tool-lead.css";

class ManageCompareToolLead extends Component {
	constructor(props) {
		super(props);
		let { t } = this.props;

		this.state = {
			compareToolLeadId: this.props.match.params.compareToolLeadId,
			reportSent: false,
			reportSentConfirmed: false,
			reportSendError: false,
			hasError: false,
			errorText: t("Error saving the report. Please try again."),
			saveSuccess: false,
			loading: false,
			loadingFailed: false,
			id: 0,
			name: "",
			contactName: "",
			contactPhone: "",
			contactEmail: "",
			address: "",
			city: "",
			stateProv: "",
			country: "",
			zipPostal: "",
			phone: "",
			vertical: "",
			overallScore: 0,
			googleRankScore: 0,
			webPresenceScore: 0,
			missingProfiles: 0,
			inaccurateProfiles: 0,
			reputationScore: 0,
			averageRating: 0,
			totalReviews: 0,
			website: "",
			googlePlaceId: "",
			googlePlaceCid: "",
			googlePlaceUrl: "",
			facebook: null,
			google: null,
			yelp: null,
			competitors: null,
			lastSentAt: "",
			leadUrl: null,

			edited: false,
			clipboardFb: '{"averageRating": "1.0","totalReviews":"1"}',
			clipboardGoogle: '{"averageRating":"1.0","totalReviews":"1","rank":"1"}',
			clipboardYelp: '{"averageRating": "1.0","totalReviews":"1"}',
			clipboardCompetitors:
				'[{"name":"Donkey Central","score":"80","website":"https://gogol.ca","googleRankScore":"99","webPresenceScore":"92","missingProfiles": "1","inaccurateProfiles":"3","reputationScore":"83","averageRating":"3.9","totalReviews":"556","facebook":{"averageRating":"3.2","totalReviews":"112"},"google":{"averageRating":"4.0","totalReviews":"455","rank":"6"},"yelp":{"averageRating": "3.2","totalReviews": "122"}}]',
			validFacebookJSON: true,
			validGoogleJSON: true,
			validYelpJSON: true,
			validCompetitorsJSON: true,

			showConfirmSave: false,
			confirmSaveText: []
		};

		this.facebookRef = null;
		this.googleRef = null;
		this.yelpRef = null;
		this.competitorsRef = null;
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.fetchLead();
	}

	handleGenericEventHandler = event => {
		this.setState({ [event.target.name]: event.target.value, edited: true });
	};

	onChange(name, text) {
		this.setState({ [name]: text.jsObject, edited: true });
	}

	async fetchLead() {
		const { compareToolLeadId } = this.state;

		let lead = await CompanyService.fetchCompareToolLead(compareToolLeadId);

		if (!lead) {
			return;
		}

		this.setState({
			id: lead.id,
			name: lead.name,
			contactName: lead.contact_name,
			contactPhone: lead.contact_phone,
			contactEmail: lead.contact_email,
			address: lead.address,
			city: lead.city,
			stateProv: lead.state_prov,
			country: lead.country,
			zipPostal: lead.zip_postal,
			phone: lead.phone,
			vertical: lead.vertical,
			overallScore: lead.overall_score,
			googleRankScore: lead.google_rank_score,
			webPresenceScore: lead.web_presence_score,
			missingProfiles: lead.missing_profiles,
			inaccurateProfiles: lead.inaccurate_profiles,
			reputationScore: lead.reputation_score,
			averageRating: lead.average_rating,
			totalReviews: lead.total_reviews,
			website: lead.website,
			googlePlaceId: lead.google_place_id,
			googlePlaceCid: lead.google_place_cid,
			googlePlaceUrl: lead.google_place_url,
			facebook: lead.facebook ? JSON.parse(lead.facebook) : null,
			google: lead.google ? JSON.parse(lead.google) : null,
			yelp: lead.yelp ? JSON.parse(lead.yelp) : null,
			competitors: lead.competitors ? JSON.parse(lead.competitors) : null,
			lastSentAt: lead.last_sent_at ? lead.last_sent_at : "",
			leadUrl: lead.url ? lead.url : null
		});
	}

	validInput = () => {
		let {
			name,
			contactName,
			contactPhone,
			contactEmail,
			overallScore,
			googleRankScore,
			webPresenceScore,
			missingProfiles,
			inaccurateProfiles,
			reputationScore,
			averageRating,
			totalReviews,
			facebook,
			google,
			yelp,
			competitors
		} = this.state;
		let { t } = this.props;

		let isValid = true;
		let errorMessages = [];

		try {
			if (!name) {
				isValid = false;
				errorMessages.push(t("Missing Name"));
			}

			if (!contactName) {
				isValid = false;
				errorMessages.push(t("Missing Contact Name"));
			}

			if (!contactPhone) {
				isValid = false;
				errorMessages.push(t("Missing Contact Phone"));
			}

			if (!contactEmail) {
				isValid = false;
				errorMessages.push(t("Missing Contact Email"));
			}

			if (!overallScore) {
				isValid = false;
				errorMessages.push(t("Missing Overall Score"));
			}

			if (!googleRankScore) {
				isValid = false;
				errorMessages.push(t("Missing Google Rank Score"));
			}

			if (!webPresenceScore) {
				isValid = false;
				errorMessages.push(t("Missing Web Presence Score"));
			}

			if (!missingProfiles) {
				isValid = false;
				errorMessages.push(t("Missing Profiles"));
			}

			if (!inaccurateProfiles) {
				isValid = false;
				errorMessages.push(t("Missing Inaccurate Profiles"));
			}

			if (!reputationScore) {
				isValid = false;
				errorMessages.push(t("Missing ReputationScore"));
			}

			if (!averageRating) {
				isValid = false;
				errorMessages.push(t("Missing Average Rating"));
			}

			if (!totalReviews) {
				isValid = false;
				errorMessages.push(t("Missing Total Reviews"));
			}

			if (!facebook) {
				isValid = false;
				errorMessages.push(t("Missing Facebook"));
			}

			if (facebook && !facebook.averageRating) {
				isValid = false;
				errorMessages.push(t("Missing Facebook Average Rating"));
			}

			if (facebook && !facebook.totalReviews) {
				isValid = false;
				errorMessages.push(t("Missing Facebook Total Reviews"));
			}

			if (!google) {
				isValid = false;
				errorMessages.push(t("Missing Google"));
			}

			if (google && !google.averageRating) {
				isValid = false;
				errorMessages.push(t("Missing Google Average Rating"));
			}

			if (google && !google.totalReviews) {
				isValid = false;
				errorMessages.push(t("Missing Google Total Reviews"));
			}

			if (google && !google.rank) {
				isValid = false;
				errorMessages.push(t("Missing Google Rank"));
			}

			if (!yelp) {
				isValid = false;
				errorMessages.push(t("Missing Yelp"));
			}

			if (yelp && !yelp.averageRating) {
				isValid = false;
				errorMessages.push(t("Missing Yelp Average Rating"));
			}

			if (yelp && !yelp.totalReviews) {
				isValid = false;
				errorMessages.push(t("Missing Yelp Total Reviews"));
			}

			if (!competitors || competitors.length < 1) {
				isValid = false;
				errorMessages.push(t("Missing Competitors"));
			}

			if (competitors && competitors.length > 1) {
				for (let i = 0; i < competitors.length; i++) {
					const competitor = competitors[i];
					const competitorNumber = i + 1;

					if (!competitor.score) {
						isValid = false;
						errorMessages.push(t("Missing Overall Score For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (!competitor.googleRankScore) {
						isValid = false;
						errorMessages.push(t("Missing Google Rank Score For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (!competitor.webPresenceScore) {
						isValid = false;
						errorMessages.push(t("Missing Web Presence Score For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (!competitor.missingProfiles) {
						isValid = false;
						errorMessages.push(t("Missing Profiles For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (!competitor.inaccurateProfiles) {
						isValid = false;
						errorMessages.push(t("Missing Inaccurate Profiles For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (!competitor.reputationScore) {
						isValid = false;
						errorMessages.push(t("Missing ReputationScore For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (!competitor.averageRating) {
						isValid = false;
						errorMessages.push(t("Missing Average Rating For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (!competitor.totalReviews) {
						isValid = false;
						errorMessages.push(t("Missing Total Reviews For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (competitor.facebook && !competitor.facebook) {
						isValid = false;
						errorMessages.push(t("Missing Facebook For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (competitor.facebook && !competitor.facebook.averageRating) {
						isValid = false;
						errorMessages.push(t("Missing Facebook Average Rating For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (competitor.facebook && !competitor.facebook.totalReviews) {
						isValid = false;
						errorMessages.push(t("Missing Facebook Total Reviews For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (!competitor.google) {
						isValid = false;
						errorMessages.push(t("Missing Google For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (competitor.google && !competitor.google.averageRating) {
						isValid = false;
						errorMessages.push(t("Missing Google Average Rating For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (competitor.google && !competitor.google.totalReviews) {
						isValid = false;
						errorMessages.push(t("Missing Google Total Reviews For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (competitor.google && !competitor.google.rank) {
						isValid = false;
						errorMessages.push(t("Missing Google Rank For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (!competitor.yelp) {
						isValid = false;
						errorMessages.push(t("Missing Yelp For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (competitor.yelp && !competitor.yelp.averageRating) {
						isValid = false;
						errorMessages.push(t("Missing Yelp Average Rating For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}

					if (competitor.yelp && !competitor.yelp.totalReviews) {
						isValid = false;
						errorMessages.push(t("Missing Yelp Total Reviews For Competitor {{competitorNumber}}", { competitorNumber: competitorNumber }));
					}
				}
			}
		} catch (error) {
			console.log(error);
		}

		return { isValid, errorMessages };
	};

	onSaveClick = () => {
		let { isValid, errorMessages } = this.validInput();

		if (!isValid) {
			this.setState({
				showConfirmSave: true,
				confirmSaveText: errorMessages
			});

			return;
		}

		this.handleSaveLeadClick();
	};

	handleSaveLeadClick = async event => {
		let { edited } = this.state;
		if (!edited) {
			return;
		}

		const authToken = UserService.get() ? UserService.get().auth_token : "";

		const leadData = {
			name: this.state.name,
			contact_name: this.state.contactName,
			contact_phone: this.state.contactPhone,
			contact_email: this.state.contactEmail,
			address: this.state.address,
			city: this.state.city,
			state_prov: this.state.stateProv,
			country: this.state.country,
			zip_postal: this.state.zipPostal,
			phone: this.state.phone,
			vertical: this.state.vertical,
			overall_score: this.state.overallScore,
			google_rank_score: this.state.googleRankScore,
			web_presence_score: this.state.webPresenceScore,
			missing_profiles: this.state.missingProfiles,
			inaccurate_profiles: this.state.inaccurateProfiles,
			reputation_score: this.state.reputationScore,
			average_rating: this.state.averageRating,
			total_reviews: this.state.totalReviews,
			website: this.state.website,
			google_place_id: this.state.googlePlaceId,
			google_place_cid: this.state.googlePlaceCid,
			google_place_url: this.state.googlePlaceUrl,
			facebook: this.state.facebook && JSON.stringify(this.state.facebook).length > 0 ? JSON.stringify(this.state.facebook) : "",
			google: this.state.google && JSON.stringify(this.state.google).length > 0 ? JSON.stringify(this.state.google) : "",
			yelp: this.state.yelp && JSON.stringify(this.state.yelp).length > 0 ? JSON.stringify(this.state.yelp) : "",
			competitors: JSON.stringify(this.state.competitors)
		};

		this.setState({ loading: true });

		let success = await CompanyService.createCompareToolLead(leadData);

		if (!success) {
			this.setState({ hasError: true, loading: false });
			return;
		}

		this.setState({ saveSuccess: true, loading: false });
	};

	async sendReport() {
		let data = {
			id: this.state.compareToolLeadId
		};

		let success = await CompanyService.sendCompareToolLeadReport(data);

		if (!success) {
			this.setState({
				reportSendError: true
			});
			return;
		}

		this.setState({
			reportSent: true
		});
	}

	copyToClipboard = (text, ref) => {
		let { t } = this.props;

		if (navigator.clipboard) {
			navigator.clipboard.writeText(text);
			ToastService.info(t("Copied to clipboard"));
			return;
		}

		//ie 11 hack
		if (window.clipboardData) {
			window.clipboardData.setData("text", text);
			ToastService.info(t("Copied to clipboard!"));
			return;
		}

		// safari hack
		if (ref) {
			ref.select();
			document.execCommand("copy");
			ToastService.info(t("Copied to clipboard!"));
		}
	};

	onCloseSaveConfirm = confirm => {
		if (confirm) {
			this.handleSaveLeadClick();
		}

		this.setState({
			showConfirmSave: false,
			confirmSaveText: []
		});
	};

	render() {
		let { t } = this.props;

		if (this.state.saveSuccess || this.state.reportSentConfirmed) {
			return <Redirect to="/reputation-report" />;
		}

		if (this.state.loading) {
			return (
				<div className="Common__spinnerdiv--center">
					<Spinners loading={true} type="tail-fade" size="120px" />
				</div>
			);
		}

		let { edited } = this.state;

		return (
			<LegacyPage>
				<div>
					<Alert
						type="success"
						show={this.state.reportSent}
						title={t("Report Sent")}
						confirm={t("OK")}
						onClose={() => this.setState({ reportSent: false, reportSentConfirmed: true })}
					>
						<div>{t("The email report has been sent successfully.")}</div>
					</Alert>
					<Alert type="info" show={this.state.showConfirmSave} title={t("Are you sure?")} confirm={t("Yes")} cancel={t("No")} onClose={this.onCloseSaveConfirm}>
						{this.state.confirmSaveText && this.state.confirmSaveText.map(text => <div>{text}</div>)}
					</Alert>
					<Alert type="error" show={this.state.hasError} title={t("Error")} confirm={t("OK")} onClose={() => this.setState({ hasError: false, errorText: "" })}>
						<div>{this.state.errorText}</div>
					</Alert>
					<Alert
						type="error"
						show={this.state.reportSendError}
						title={t("Error")}
						confirm={t("OK")}
						onClose={() => this.setState({ reportSendError: false, errorText: "" })}
					>
						<div>{t("There was an error sending the report. Please try again.")}</div>
					</Alert>
					<div className="row wrapper border-bottom white-bg page-heading">
						<div className="col-lg-12">
							<h2>
								{this.state.name} - {t("Reputation Report")}
							</h2>
						</div>
					</div>

					<div className="wrapper wrapper-content main-table-margin companies__table--padding">
						<div className="row">
							<div className="col-lg-12">
								<div className="ibox float-e-margins">
									<div className="ibox-content">
										<div className="form-horizontal">
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Name")}</label>
												<div className="col-sm-10">
													<input
														name="name"
														id="name"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.name}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Contact Name")}</label>
												<div className="col-sm-10">
													<input
														name="contactName"
														id="contactName"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.contactName}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Contact Phone")}</label>
												<div className="col-sm-10">
													<input
														name="contactPhone"
														id="contactPhone"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.contactPhone}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Contact Email")}</label>
												<div className="col-sm-10">
													<input
														name="contactEmail"
														id="contactEmail"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.contactEmail}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Address")}</label>
												<div className="col-sm-10">
													<input
														name="address"
														id="address"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.address}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("City")}</label>
												<div className="col-sm-10">
													<input
														name="city"
														id="city"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.city}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("State/Province")}</label>
												<div className="col-sm-10">
													<input
														name="stateProv"
														id="stateProv"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.stateProv}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Country")}</label>
												<div className="col-sm-10">
													<input
														name="country"
														id="country"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.country}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Zip Postal Code")}</label>
												<div className="col-sm-10">
													<input
														name="zipPostal"
														id="zipPostal"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.zipPostal}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Phone")}</label>
												<div className="col-sm-10">
													<input
														name="phone"
														id="phone"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.phone}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Vertical")}</label>
												<div className="col-sm-10">
													<input
														name="vertical"
														id="vertical"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.vertical}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Overall Score")}</label>
												<div className="col-sm-10">
													<input
														name="overallScore"
														id="overallScore"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.overallScore}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Google Rank Score")}</label>
												<div className="col-sm-10">
													<input
														name="googleRankScore"
														id="googleRankScore"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.googleRankScore}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Web Presence Score")}</label>
												<div className="col-sm-10">
													<input
														name="webPresenceScore"
														id="webPresenceScore"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.webPresenceScore}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Number of Missing Profiles")}</label>
												<div className="col-sm-10">
													<input
														name="missingProfiles"
														id="missingProfiles"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.missingProfiles}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Number of Inaccurate Profiles")}</label>
												<div className="col-sm-10">
													<input
														name="inaccurateProfiles"
														id="inaccurateProfiles"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.inaccurateProfiles}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Reputation Score")}</label>
												<div className="col-sm-10">
													<input
														name="reputationScore"
														id="reputationScore"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.reputationScore}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Average Rating")}</label>
												<div className="col-sm-10">
													<input
														name="averageRating"
														id="averageRating"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.averageRating}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Total Reviews")}</label>
												<div className="col-sm-10">
													<input
														name="totalReviews"
														id="totalReviews"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.totalReviews}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Website")}</label>
												<div className="col-sm-10">
													<input
														name="website"
														id="website"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.website}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Google Place Id")}</label>
												<div className="col-sm-10">
													<input
														name="googlePlaceId"
														id="googlePlaceId"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.googlePlaceId}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Google Place Cid")}</label>
												<div className="col-sm-10">
													<input
														name="googlePlaceCid"
														id="googlePlaceCid"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.googlePlaceCid}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Google Place Url")}</label>
												<div className="col-sm-10">
													<input
														name="googlePlaceUrl"
														id="googlePlaceUrl"
														type="text"
														onChange={this.handleGenericEventHandler}
														value={this.state.googlePlaceUrl}
														className="form-control"
														required
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Facebook")}</label>
												<div className="col-sm-10">
													<textarea
														id="facebook-textarea"
														className="compare-tool__copy-textarea"
														ref={ref => (this.facebookRef = ref)}
														value={this.state.clipboardFb}
													/>
													<div className="btn btn-default" onClick={() => this.copyToClipboard(this.state.clipboardFb, this.facebookRef)}>
														{t("Copy Template")}
													</div>
													<br />
													<br />
													<JSONInput
														id="a_unique_id"
														placeholder={this.state.facebook}
														locale={locale}
														height="200px"
														onChange={o => this.onChange("facebook", o)}
														onBlur={o => this.onChange("facebook", o)}
														waitAfterKeyPress={3000}
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Google")}</label>
												<div className="col-sm-10">
													<textarea
														id="google-textarea"
														className="compare-tool__copy-textarea"
														ref={ref => (this.googleRef = ref)}
														value={this.state.clipboardGoogle}
													/>
													<div className="btn btn-default" onClick={() => this.copyToClipboard(this.state.clipboardGoogle, this.googleRef)}>
														{t("Copy Template")}
													</div>
													<br />
													<br />
													<JSONInput
														id="google"
														placeholder={this.state.google}
														locale={locale}
														height="200px"
														onChange={o => this.onChange("google", o)}
														onBlur={o => this.onChange("google", o)}
														waitAfterKeyPress={3000}
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Yelp")}</label>
												<div className="col-sm-10">
													<textarea
														id="yelp-textarea"
														className="compare-tool__copy-textarea"
														ref={ref => (this.yelpRef = ref)}
														value={this.state.clipboardYelp}
													/>
													<div className="btn btn-default" onClick={() => this.copyToClipboard(this.state.clipboardYelp, this.yelpRef)}>
														{t("Copy Template")}
													</div>
													<br />
													<br />
													<JSONInput
														id="yelp"
														placeholder={this.state.yelp}
														locale={locale}
														height="200px"
														onChange={o => this.onChange("yelp", o)}
														onBlur={o => this.onChange("yelp", o)}
														waitAfterKeyPress={3000}
													/>
												</div>
											</div>
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Competitors")}</label>
												<div className="col-sm-10">
													<textarea
														id="competitors-textarea"
														className="compare-tool__copy-textarea"
														ref={ref => (this.competitorsRef = ref)}
														value={this.state.clipboardCompetitors}
													/>
													<div className="btn btn-default" onClick={() => this.copyToClipboard(this.state.clipboardCompetitors, this.competitorsRef)}>
														{t("Copy Template")}
													</div>
													<br />
													<br />
													<JSONInput
														id="competitors"
														placeholder={this.state.competitors}
														locale={locale}
														height="550px"
														onChange={o => this.onChange("competitors", o)}
														onBlur={o => this.onChange("competitors", o)}
														waitAfterKeyPress={3000}
													/>
												</div>
											</div>
											<div className="hr-line-dashed" />
											<div className="form-group">
												<label className="col-sm-2 control-label">{t("Last Sent At")}</label>
												<div className="col-sm-10">
													<div className="input-group">
														<span className="form-control">
															{this.state.lastSentAt !== "" ? moment(this.state.lastSentAt).format("YYYY-MM-DD hh:mm a") : ""}
														</span>
													</div>
												</div>
											</div>
											<div className="form-group">
												<div className="col-sm-4 col-sm-offset-2">
													{this.state.leadUrl && (
														<a id="compare-tool-url" target="_blank" rel="noopener noreferrer" className="btn btn-default" href={this.state.leadUrl}>
															{t("See Lead In Compare Tool")}
														</a>
													)}
												</div>
											</div>

											<div className="form-group">
												<div className="col-sm-4 col-sm-offset-2">
													<button id="save" className="btn btn-primary" disabled={!edited} onClick={this.onSaveClick}>
														{t("Save")}
													</button>
													&nbsp;
													<Link to="/reputation-report" className="btn btn-warning">
														{t("Cancel")}
													</Link>
													&nbsp;
													<button id="send" className="btn btn-default" onClick={e => this.sendReport(e)}>
														{t("Send Report")}
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LegacyPage>
		);
	}
}

export default withTranslation(null, { withRef: true })(ManageCompareToolLead);
