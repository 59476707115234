import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import * as Icon from "react-feather";
import Select from "react-select";
import { withTranslation } from "react-i18next";

import UserService from "../../services/UserService";
import GroupPermissionService from "../../services/GroupPermissionService";
import CompanyService from "../../services/CompanyService";
import GAService from "../../services/GAService";
import UtilityService from "../../services/UtilityService";
import TeamChatService from "../../services/TeamChatService";
import ToastService from "../../services/ToastService";

import Toggle from "../../components/common/Toggle";
import Checkbox from "../../components/common/Checkbox";
import Spinners from "../../components/common/Spinners";
import SearchInput from "../../components/common/SearchInput";
import Page from "../../components/common/Page";
import Header from "../../components/common/Header";
import withLocation from "../../components/common/WithLocation";

import { CONVERSATION, KEYS } from "../../constants/Messenger";
import { USER_INVITES_STYLES } from "../../constants/UserInvites";

import "../../styles/css/scenes/user_invites.css";

class UserInvites extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			users: [],
			emailsText: "",
			selectedGroup: null,
			assignableLocations: [],
			assignableGroups: [],
			assignableTeamchatChannels: [],
			isTeamchatAllowed: false,

			inviteResults: null
		};

		this.emailInput = null;
	}

	componentDidMount() {
		GAService.GAPageView({ page: this.props.location.pathname });
		this.resetComponent();
	}

	onLocationChanged = async location => {
		await this.resetComponent();
	};

	update(o) {
		return new Promise(resolve => {
			this.setState(o, resolve);
		});
	}

	resetComponent = async () => {
		await this.update({ loading: true });

		await this.update({
			users: [],
			emailsText: "",
			selectedGroup: null,
			assignableLocations: [],
			assignableGroups: [],
			assignableTeamchatChannels: [],
			isTeamchatAllowed: false,

			inviteResults: null
		});

		await this.fetchLocations();
		await this.fetchGroups();
		await this.fetchTeamChatChannels();

		await this.update({ loading: false });
	};

	async fetchLocations() {
		let company = UserService.getActiveCompany();

		let locations = await CompanyService.fetchLocations(company.id);
		let assignableLocations = locations.map(location => {
			return {
				value: location.id,
				label: location.name
			};
		});

		await this.update({ assignableLocations });
	}

	async fetchGroups() {
		let groups = await GroupPermissionService.fetchSubordinateAccounts();

		groups = groups.map(g => {
			return {
				value: g.id,
				label: g.name,
				type: g.type
			};
		});

		// Filter out supers and CS
		groups = groups.filter(g => g.type !== "customer_success" && g.type !== "super_administrator");

		await this.update({
			assignableGroups: groups
		});
	}

	sendInvites = async () => {
		let { users } = this.state;
		let { t } = this.props;

		let company = UserService.getActiveCompany();

		if (this.isSendInvitesDisabled()) {
			return;
		}

		await this.update({ loading: true });

		let reducedUsers = users.map(user => {
			let email = user.email;
			let group = user.group.value;
			let locationIds = user.assignedLocations.map(l => l.value);
			let teamchatChannels = user.teamchatChannels;
			let enableTeamchatForUser = user.enableTeamchat;

			return {
				email,
				group,
				locationIds,
				teamchatChannels,
				enableTeamchatForUser
			};
		});

		let inviteResults = await CompanyService.inviteUsers(company.id, reducedUsers);

		if (inviteResults) {
			await this.update({ inviteResults: inviteResults });
		} else {
			ToastService.error(t("Error saving user invites. Please try again."));
		}

		await this.update({ loading: false });
	};

	async handleTextOnChange(value) {
		await this.update({
			emailsText: value
		});
	}

	fetchTeamChatChannels = async () => {
		let user = UserService.get();

		let latestUser = await UserService.fetchUser(user.id);
		let company = await CompanyService.fetchCompany(latestUser.company_id);
		let isSuperOrCsVisitingAnotherCompany = UserService.isSuperOrCsVisitingAnotherCompany();

		let isAllowed = user.enable_teamchat && company.enable_teamchat && !isSuperOrCsVisitingAnotherCompany;

		await this.update({ isTeamchatAllowed: isAllowed });

		if (!isAllowed) {
			return;
		}

		let assignableTeamchatChannels = await TeamChatService.fetchConversations({ userId: user.id, type: CONVERSATION.channel });

		if (!assignableTeamchatChannels) {
			return;
		}

		await this.update({ assignableTeamchatChannels });
	};

	onEnter = async e => {
		if (e.keyCode === KEYS.enter) {
			e.preventDefault();

			await this.checkUser();
		}
	};

	checkUser = async () => {
		let { users, emailsText } = this.state;

		if (emailsText.trim() === "") {
			return;
		}

		if (!UtilityService.isEmail(emailsText)) {
			return;
		}

		let emailExists = users.filter(u => u.email === emailsText);
		if (emailExists.length > 0) {
			return;
		}

		await this.addUser();
	};

	async addUser() {
		let { users, emailsText, isTeamchatAllowed } = this.state;

		users.unshift({
			email: emailsText,
			group: null,
			assignedLocations: [],
			teamchatChannels: [],
			enableTeamchat: isTeamchatAllowed
		});

		await this.update({
			users,
			emailsText: ""
		});

		if (this.emailInput) {
			this.emailInput.onClose();
		}
	}

	async removeUser(index) {
		let { users } = this.state;

		users.splice(index, 1);

		await this.update({
			users
		});
	}

	isSendInvitesDisabled() {
		let { users } = this.state;

		let isDisabled = false;

		for (const user of users) {
			if (!user.email) {
				isDisabled = true;
				break;
			}
			if (!user.group) {
				isDisabled = true;
				break;
			}
			if (user.assignedLocations.length === 0) {
				isDisabled = true;
				break;
			}
		}

		return isDisabled;
	}

	onGroupChange = (index, newGroup) => {
		let { users } = this.state;

		let usersCopy = users.slice();

		usersCopy[index].group = newGroup;

		this.update({ users: usersCopy });
	};

	onAssignedLocationChange = (index, assignedLocations) => {
		let { users } = this.state;

		let usersCopy = users.slice();

		usersCopy[index].assignedLocations = assignedLocations;

		this.update({ users: usersCopy });
	};

	enableTeamchatForUserChange = (index, enabled) => {
		let { users } = this.state;

		let usersCopy = users.slice();

		usersCopy[index].enableTeamchat = enabled;

		this.update({ users: usersCopy });
	};

	onteamChatJoinCheckmarkEvent = ({ index, teamchatId, checked }) => {
		let { users } = this.state;

		let usersCopy = users.slice();

		let teamchatChannelsSelectedList = usersCopy[index].teamchatChannels;

		if (checked) {
			teamchatChannelsSelectedList.push(teamchatId);
		} else {
			var indexOfTeamchat = teamchatChannelsSelectedList.indexOf(teamchatId);
			if (indexOfTeamchat !== -1) {
				teamchatChannelsSelectedList.splice(indexOfTeamchat, 1);
			}
		}

		this.update({ users: usersCopy });
	};

	renderUsers() {
		let { users, assignableLocations, assignableGroups, assignableTeamchatChannels, isTeamchatAllowed } = this.state;
		let { t } = this.props;

		return users.map((user, index) => {
			let userEmail = user.email;
			let userGroup = user.group;
			let userAssignedLocations = user.assignedLocations;
			let userEnableTeamchat = user.enableTeamchat;
			let userTeamchatChannels = user.teamchatChannels;

			return (
				<div key={userEmail} className="invite-users__form-item">
					<div className="invite-users__form-item--email">
						<span>{userEmail}</span>
						<span>
							<Icon.X onClick={() => this.removeUser(index)} />
						</span>
					</div>
					<label className="invite-users__form-item--group">{t("Group")}</label>
					<Select
						id="group"
						name="group"
						options={assignableGroups}
						value={userGroup ? userGroup : null}
						onChange={selectedGroup => this.onGroupChange(index, selectedGroup)}
						placeholder={t("Select a group")}
					/>
					<label className="invite-users__form-item--group">{t("Assigned Locations")}</label>
					<Select
						id="locations"
						name="locations"
						options={assignableLocations}
						value={userAssignedLocations ? userAssignedLocations : null}
						onChange={assignedLocations => this.onAssignedLocationChange(index, assignedLocations)}
						placeholder={t("Assigned Users")}
						isMulti
						styles={USER_INVITES_STYLES.select}
					/>
					{isTeamchatAllowed && (
						<>
							<Toggle
								id="userEnableTeamchat"
								label={t("Enable Teamchat For User")}
								description={t("Allow the user to access teamchat.")}
								checked={userEnableTeamchat}
								onChange={enabled => this.enableTeamchatForUserChange(index, enabled)}
							/>

							{userEnableTeamchat && (
								<>
									<label className="invite-users__form-item--group">{t("Team Chat Channels")}</label>
									<div className="invite-users__teamchat-channels">
										{assignableTeamchatChannels.map(convo => {
											let conversationId = convo.id;
											let conversationName = convo.name;

											return (
												<div key={conversationId} className="invite-users__teamchat-channels-row">
													<div className="invite-users__teamchat-channels-row__action">
														<Checkbox
															id={`${conversationId}-checkbox`}
															name={conversationName}
															checked={userTeamchatChannels.includes(conversationId) ? true : false}
															onChange={event => {
																this.onteamChatJoinCheckmarkEvent({ index, teamchatId: conversationId, checked: event.target.checked });
															}}
														/>
													</div>
													<div className="invite-users__teamchat-channels-row__name">{conversationName}</div>
												</div>
											);
										})}
									</div>
								</>
							)}
						</>
					)}
				</div>
			);
		});
	}

	renderInviteFeedback() {
		let { inviteResults } = this.state;
		let { t } = this.props;

		return (
			<div className="invite-users__feedback">
				<div className="invite-users__feedback__header">{inviteResults.length > 1 ? t("Invites queued") : t("Invite queued")} </div>
				<div className="invite-users__feedback-list">
					{inviteResults.map(result => {
						let resultEmail = result.email;
						let resultError = result.error;
						let resultSuccess = result.success;

						return (
							<div key={resultEmail} className="invite-users__feedback-list__item">
								<div>{resultEmail}</div>
								{resultError && (
									<div className="Common__feedback-fail">
										<div data-tip data-for={`invite-fail-${resultEmail}`}>
											<Icon.X />
										</div>
										<ReactTooltip id={`invite-fail-${resultEmail}`} className="mb-react-tooltip" arrowColor="#333" type="info" effect="solid" place="right">
											<div>{resultError}</div>
										</ReactTooltip>
									</div>
								)}
								{resultSuccess && (
									<div className="Common__feedback-success">
										<Icon.Check />
									</div>
								)}
							</div>
						);
					})}
				</div>
				<div className="invite-users__form-save-btn">
					<div className="mb-button" onClick={this.resetComponent}>
						{t("Send More Invites")}
					</div>
				</div>
			</div>
		);
	}

	renderPage() {
		let { emailsText, users, inviteResults } = this.state;
		let { t } = this.props;

		if (inviteResults) {
			return <div className="invite-users__form">{this.renderInviteFeedback()}</div>;
		}

		let hasUsers = users.length > 0;

		return (
			<div className="invite-users__form">
				<div className="invite-users__search">
					<SearchInput
						ref={ref => (this.emailInput = ref)}
						placeholder={t("Enter an email address ...")}
						value={emailsText}
						onChange={text => this.handleTextOnChange(text)}
						onKeyDown={this.onEnter}
					/>
					<div className={`mb-button`} onClick={this.checkUser}>
						{t("Add")}
					</div>
				</div>
				{this.renderUsers()}
				{hasUsers && (
					<div className="invite-users__form-save-btn">
						<div className={`mb-button ${this.isSendInvitesDisabled() ? "mb-send--disabled" : ""}`} onClick={this.sendInvites}>
							{t("Send Invites")}
						</div>
					</div>
				)}
			</div>
		);
	}

	render() {
		let { loading } = this.state;

		return (
			<Page>
				<Header title="Invite Users" backUrl="/settings/users" />
				{!loading && this.renderPage()}
				{loading && (
					<div className="Common__spinnerdiv--center">
						<Spinners loading={true} type="tail-fade" size="120px" />
					</div>
				)}
			</Page>
		);
	}
}

export default withRouter(withLocation(withTranslation(null, { withRef: true })(UserInvites)));
